import { Item } from '../api/items/types';

export function getItemSubPath({ gender_category }: Item) {
  return (
    gender_category == 'Dam' ? 'women'
    : gender_category == 'Herr' ? 'men'
    : gender_category == 'Kids' ? 'kids'
    : 'items'
    );
}
