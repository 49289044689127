import React from 'react';
import styled from 'styled-components';
import Typography from '../../../components/Typography';

const NavLink = styled(Typography).attrs(({ className, ...attrs }) => ({
  className: ['nav-link', className].join(' '),
  ...attrs
}))`
  cursor: pointer;
  white-space: nowrap;
`;

export default NavLink;
