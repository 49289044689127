import React from 'react';
import { BottomLogo, ReCRQLLogo } from './Logo';
import { createUseStyles } from 'react-jss';
import { colors, properties } from '../theme';
import { useTranslation } from 'react-i18next';
import NavLink from '../views/common/navigation/NavLink';
import { useIsMobile } from '../utils/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import ExternalLink from '../views/common/navigation/ExternalLink';
import Touchable from './Touchable';
import SvgImg from './SvgImg';

type StyleProps = {
  isMobile: boolean;
}
const useStyles = createUseStyles<string, StyleProps>({
  logoContainer: {
    backgroundColor: colors.backgroundPrimary,
    padding: '40px 76px',
  },
  linksContainer: {
    backgroundColor: colors.backgroundSecondary,
    padding: ({ isMobile }) => isMobile ? '50px' : '54px 78px 146px 78px',
  },
  linksSubContainer: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ({ isMobile }) => isMobile ? 'column' : 'inherit',
    alignItems: ({ isMobile }) => isMobile ? 'center' : 'inherit',
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const FooterNavLink = ({
  path,
  label,
  isTop,
  noMargin,
}: {
  path: string;
  label: string;
  isTop?: boolean;
  noMargin?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <NavLink
      variant='general-button1'
      onClick={() => navigate(path)}
      style={noMargin ? {} : { marginTop: isMobile ? 27 : isTop ? 0 : 7 }} 
    >
      {t(label)}
    </NavLink>
  );
}

export default function Footer() {
  const isMobile = useIsMobile();
  const styles = useStyles({ isMobile });
  const links = [
    <FooterNavLink key='link-support' path='/support' label='footer.support' noMargin />,
    <FooterNavLink key='link-faq' path='/faq' label='footer.faq' />,
    <FooterNavLink key='tos' path='/tos' label='footer.tos' />,
    <FooterNavLink key='privacy-policy' path='/gdpr' isTop label='footer.privacyPolicy' />,
    <FooterNavLink key='about-recrql' path='/about' label='footer.aboutRecrql' />,
    <ExternalLink
      key='partner-external-link'
      style={{ marginTop: isMobile ? 27 : 0 }}
      onClick={() => {
        window.open(properties.storeLink.uri);
      }}
      TypographyProps={{
        variant: 'general-button1'
      }}
    >
      {properties.storeLink.title}
    </ExternalLink>,
    <div key='recrql-app-links' style={{
      marginTop: isMobile ? 51 : 0,
      alignItems: isMobile ? 'center' : 'auto',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <ReCRQLLogo size='large' />
      <div style={{ display: 'flex', marginTop: '16px' }}>
        <Touchable onClick={() => window.open(properties.appLinks.ios)} style={{ marginRight: '16px' }}>
          <SvgImg src={'assets/icon_appstore_se.svg'} />
        </Touchable>
        <Touchable onClick={() => window.open(properties.appLinks.android)}>
          <SvgImg src={'assets/icon_googleplay_se.svg'} />
        </Touchable>
      </div>
    </div>
  ];
  return (
    <div>
      <div className={styles.linksContainer}>
        <div className={styles.linksSubContainer}>
          {isMobile ? links : (
            <>
              <div className={styles.col}>
                {links[0]}
                {links[1]}
                {links[2]}
              </div>
              <div className={styles.col}>
                {links[3]}
                {links[4]}
              </div>
              <div className={styles.col}>
                {links[5]}
              </div>
              <div className={styles.col}>
                {links[6]}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.logoContainer}>
        <BottomLogo />
      </div>
    </div>
  );
}
