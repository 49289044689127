import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { createUseStyles } from 'react-jss';
import AuthContainer from './AuthContainer';
import recrqlColors from '../../theme/recrql/colors';
import { t } from 'i18next';

const useStyles = createUseStyles({
  resetPasswordConfirm: {
  }
});

export default function ResetPasswordConfirmView() {
  const styles = useStyles();
  return (
    <AuthContainer title={<div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckCircle sx={{ marginRight: '7px', fontSize: '23px' }} htmlColor={recrqlColors.logoOrAccent.background} />
      {t('forgotPassword.passwordHasBeenChanged')}
    </div>}>
      <div className={styles.resetPasswordConfirm}>
        
      </div>
    </AuthContainer>
  );
}
