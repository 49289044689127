import React from 'react';
import styled from 'styled-components';
import { colors, typographies as themeTypographies } from '../theme';
import recrqlTypographies from '../theme/recrql/typographies';
export type TypographyProps = {
  variant: TypographyVariant;
  className?: string;
  colorType?: 'default'|'faded',
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, 'ref'>;
type StyleProps = Pick<TypographyProps, 'colorType'|'variant'>;

const typographies = {
  ...themeTypographies,
  ...recrqlTypographies
}

export type TypographyVariant = keyof typeof typographies;

const StyledTypography = styled.span<StyleProps>(({ colorType, variant }) => `
  color: ${colorType == 'faded' ? 'rgba(0,0,0,0.45)' : colors.textPrimary};
  ${typographies[variant]}
`);

export default function Typography({
  children,
  variant,
  colorType = 'default',
  className,
  ...props
}: TypographyProps) {
  return (
    <StyledTypography
      className={[`typography typography-${variant}`, className].join(' ')}
      colorType={colorType}
      variant={variant}
      {...props}
    >
      {children}
    </StyledTypography>
  );
}
