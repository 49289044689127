import React from 'react';
import styled from "@emotion/styled";
import { Checkbox, CheckboxProps } from "@mui/material";
import recrqlColors from '../theme/recrql/colors';

const StyledDiv = styled.div`
  .MuiCheckbox-root.Mui-checked {
    color: ${recrqlColors.primaryDark.background}
  }
`;

const LargeCheckbox = (props: CheckboxProps) => {
  return (
    <StyledDiv>
      <Checkbox {...props} />
    </StyledDiv>
  );
}

export default LargeCheckbox;
