import React from 'react';
import styled from 'styled-components';
import { HTMLProps } from '../utils/types';
import { navHeight } from '../views/common/navigation/NavigationView';

const StyledAnchor = styled.a.attrs(({ className }) => ({
  className: ['anchor', className].join(' ')
}))`
  display: block;
  position: relative;
  visibility: hidden;
`;

export default function Anchor({
  style,
  ...props
}: HTMLProps<HTMLAnchorElement>) {
  return (
    <StyledAnchor
      style={{
        top: `-${navHeight()}px`,
        ...style,
      }}
      {...props}
    />
  );
}
