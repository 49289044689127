import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Facets } from '../../../api/common/types';
import {
  ItemSearchParams,
} from '../../../api/items/types';
import SvgImg from '../../../components/SvgImg';
import Typography from '../../../components/Typography';
import { properties } from '../../../theme';
import { useIsMobile } from '../../../utils/useWindowDimensions';
import ItemFiltersDesktopView from './ItemFiltersDesktopView';
import ItemFiltersMobileView from './ItemFiltersMobileView';
import { CheckState, FilterKey, ItemFiltersState } from './types';

type Props = {
  categories: string[]|null|undefined;
  facets?: Facets;
  itemSearchParams: ItemSearchParams;
  onSetState: (state: ItemFiltersState) => void;
};

export default function ItemFilters({
  categories,
  facets,
  itemSearchParams,
  onSetState,
}: Props) {
  const [state, setState] = useState<ItemFiltersState>(getFiltersState(initialFiltersState, facets, itemSearchParams, categories));

  function handleSetState(value: (prevState: ItemFiltersState) => ItemFiltersState) {
    setState(value);
    onSetState(value(state));
  }
  function handleToggle(key: FilterKey) {
    setState(p => {
      const o = {...p};
      Object.keys(o).forEach(k => {
        o[k] = {
          ...o[k],
          isExpanded: k == key ? !o[k].isExpanded : false
        };
      });
      return {...o};
    });
  }
  function handleCheckToggle(key: FilterKey, { label, isChecked }: CheckState) {
    handleSetState(p => {
      const items = [...p[key].items];
      const index = items.findIndex(i => i.label == label);
      items.splice(index, 1, { ...items[index], isChecked });
      return {
        ...p,
        [key]: {
          ...p[key],
          items,
        }
      };
    });
  }
  useEffect(() => {
    setState(p => getFiltersState(p, facets, itemSearchParams, categories));
  }, [facets, categories]);
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <ItemFiltersMobileView
          handleCheckToggle={handleCheckToggle}
          handleToggle={handleToggle}
          state={state}
        />
      ) : (
        <ItemFiltersDesktopView
          handleCheckToggle={handleCheckToggle}
          handleToggle={handleToggle}
          state={state}
        />
      )}
    </>
  );
}

const initialFiltersState = properties.filters.reduce((obj, filter) => ({
  ...obj,
  [filter.key]: {
    label: filter.label,
    isExpanded: false,
    items: []
  }
}), {})

function getFiltersState(
  previousState: ItemFiltersState,
  facets: Facets|undefined,
  itemSearchParams: ItemSearchParams,
  categories: string[]|null|undefined
): ItemFiltersState {
  categories = categories ?? properties.defaultCategories;
  return properties.filters.reduce((obj, filter) => {
    const key = filter.key;
    // @ts-expect-error: 123
    const buckets = (facets?.['_filter_' + key]?.[key]?.buckets ?? []).filter(({ key }) => key);
    const searchParamFilter = itemSearchParams.filter?.[key] ?? [];
    const items = (
      (filter.useData == 'categories' && !!categories) ? categories.map(key => ({ key }))
      : (filter.useData == 'options' && !!filter.options) ? Object.keys(filter.options).map(key => ({ key }))
      : buckets
    // @ts-expect-error: 123
    ).map(({ key }) => {
      const o = {
        label: key,
        isChecked: searchParamFilter.includes(key) ?? false,
      }
      if (filter.options) {
        const option = filter.options[key];
        // console.log({ options: filter.options, option, key })
        // @ts-expect-error: 123
        o.labelComponent = (
          <CheckboxLabel
            label={option.label}
            icon={option.icon}
          />
        )
      }
      return o
    })
    return {
      ...obj,
      ...{
        [key]: {
          ...previousState[key],
          items
        }
      }
    }
  }, {});
}

function CheckboxLabel ({ label, icon }: { label: string, icon?: string }) {
  const { t } = useTranslation();
  if (!icon) return <Typography variant='Body2'>{t(label)}</Typography>;
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }}>
      <CheckboxLabel label={label} />
      <SvgImg src={`assets/icons/${icon}`} width={16} />
    </div>
  );
}
