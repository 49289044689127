import React, { useEffect } from 'react';
import Typography from '../../components/Typography';
import CopyContainer, { Subtitle } from '../CopyContainer';

export default function TOS() {
  useEffect(() => document.getElementById('layout')?.scrollTo({ top: 0 }), []);
  return (
    <CopyContainer
      title='ReCRQL - Användarvillkor'
      subtitles={[
        '1. WHEREAS',
        '2. CHANGES TO THE TERMS AND THE SERVICES',
        '3. USER RESPONSIBILITY',
        '4. LISTING AND SHIPPING TERMS',
        '5. PURCHASE TERMS',
        '6. FEES AND PAYMENT',
        '7. RETURNS AND REFUNDS',
        '8. HISTORIC PURCHASES',
        '9. AVAILABILITY OF THE SERVICES',
        '10. REMOVED PICTURES, LISTINGS, SUSPENSION AND TERMINATION',
        '11. FORCE MAJEURE',
        '12. DATA MANAGEMENT',
        '13. INTELLECTUAL PROPERTY RIGHTS',
        '14. LIMITATION OF LIABILITY',
        '15. CONSUMER RIGHTS',
        '16. APPLICABLE LAW AND DISPUTES'
      ]}
    >
      <Typography variant='general-body1' style={{ display: 'block' }}>
        <Subtitle>1. WHEREAS</Subtitle>

        1.1 These   user   terms   and   conditions   (the
        ”Terms”)   always   apply,   unless   otherwise
        agreed   in   writing,   between   Hjelmberg
        Sustainable   Solutions   AB,   reg.no   559295-
        5073, (”HSS”) and the user (the ”User”) of
        HSS’   e-commerce   platform   ’reCRQL’   (the
        “Platform”)   and   thereto   related   ancillary
        services   (together   with   the   Platform   the
        ”Services”). 
        1.2 HSS and the User are hereinafter also jointly
        referred to as the ”Parties” and separately as
        a ”Party”.
        1.3 The Services allows for the User to buy or sell
        secondhand   items   on  the   platform   via   the
        reCRQL   web   shop   (the   “Webshop”),   the
        reCRQL   app   (the   “App”),   and   online
        secondhand   stores   hosted   by   the   original
        manufacturers   of   select   items   (each   a
        “Connected Manufacturer”)   
        1.4 The Parties enter into an agreement when the
        User registers, activates or reactivates a user
        account for the Services (the “Account”), or
        when   a   User   makes   a   purchase   via   the
        Services,   whereupon   the   Terms   enter   into
        force between the Parties. 
        1.5 By the aforementioned registration, activation,
        reactivation or purchase, the User agrees to
        the Terms and approves to HSS’ access to and
        processing of all information provided by the
        User when using the Services. By agreeing to
        the Terms, the User also declares that the
        User has taken part of, and understood, the
        information given in the Terms and that the
        User is of legal age to use the Services and
        enter into binding contracts.

        <Subtitle>2. CHANGES TO THE TERMS AND THE SERVICES</Subtitle>

        2.1 HSS has the right to amend or make additions
        to   the   Terms   at   any   time,   without   first
        obtaining   the   User&apos;s   approval.   An   updated
        version of the Terms will be made available on
        the Platform and will enter into force when
        made available. If the User uses the Services
        after the amended Terms have entered into
        force, the User is deemed to have approved
        the amended Terms.
        2.2 HSS has the right, at any time, to alter or
        make   additions   to   the   Services   and   their
        functions, as well as the manner in which the
        Services are provided, without prior notice.
        HSS also has the right to develop, improve,
        modify, and update the Services to the extent
        that   HSS   deems   necessary  or  appropriate.
        Such   changes   may   result   in   some   of   the
        Services&apos;   functions   being   changed,
        discontinued or added.

        <Subtitle>3. USER RESPONSIBILITY</Subtitle>

        3.1 The User undertakes to comply with Swedish
        and international legislation when using the
        Services.
        3.2 The User undertakes to indemnify HSS for
        damages, including indirect damages, which
        HSS and its representatives suffer as a result
        of the User&apos;s use of the Services in violation of
        applicable  laws  and  regulations.  The   same
        applies to technical abuse, where the User
        spread malware, viruses, trojans etc. in or
        through the Services.
        3.3 The   User   is   personally   responsible   for   all
        documents and all activities undertaken by the
        User within the framework of the Services and
        for material that the User makes available on
        or through the Services. 
        3.4 The   User   is   responsible   for   ensuring   that
        sensitive   information   such   as   login
        information,   passwords   and   the   like   are
        handled   without   the   risk   of   unauthorized
        access. In the event that the User suspects or
        should suspect that an unauthorized person
        has gained access to the user account, the
        User is obliged to take immediate action to
        restrict   access   to   the   Services,   and   to
        immediately notify HSS. The User accepts that
        HSS   is   not   responsible   for   unauthorized
        access to and misuse of the Services and for
        any consequences thereof, which are due to
        the User&apos;s negligence.
        3.5 The User undertakes not to publish offensive
        material   or   material   whose   publication
        constitutes   an   infringement   of   third   party
        rights. HSS reserves the right to determine
        when material is to be considered offensive or
        infringing.   HSS   reserves   the   right   to
        immediately terminate the User&apos;s access to
        the Services if the User publishes material of
        the kind mentioned. The User shall further
        indemnify HSS in the event that its use of the
        Services would give rise to a liability for HSS
        in relation to third parties.
        3.6 The User shall provide HSS with access to any
        information, materials, equipment, premises
        and persons necessary for the performance of
        the Services.

        <Subtitle>4. LISTING AND SHIPPING TERMS</Subtitle>

        4.1 The User may upload listings to the Services,
        to be listed on the platform via the Webshop,
        the App, and the online secondhand store of a
        Connected Manufacturer.  
        4.2 The User warrants that all listings created by
        the User will comply with the following listing
        criteria:
        a) All   photos   uploaded   to   the   listing   shall
        depict the actual listed item in an accurate
        way.   The   User   shall   not   upload   photos
        depicting other items, items displayed in a
        sexual or otherwise inappropriate way. The
        User shall not upload photos or pictures to
        which   the   User   does   not   own   the
        intellectual property rights.
        b) Any   description   of   the   item   shall   be
        accurate and portray the item in an honest
        and objective way. 
        c) The specified condition in the listing shall
        represent the actual condition of the item.  
        4.3 A listing may not be immediately shown on the
        Connected Manufacturers’ online secondhand
        store.   When   and   if   a   listing   appears   may
        depend on factors such as the listing format,
        price and shipping costs, valuation, and input
        from the Connected Manufacturer. 
        4.4 The   User   shall   use   the   shipping   method
        provided by HSS through the Services, and
        comply with HSS instructions, when shipping
        the listed items.
        4.5 The User must ship a sold item no later than
        five business days after being provided with a
        shipping code from HSS. If the item is not
        shipped within said five business days, the
        buyer of the item shall have the right to cancel
        the purchase and HSS shall have the right to
        charge the User for the cost of the booked
        shipping.

        <Subtitle>5. PURCHASE TERMS</Subtitle>

        5.1 By purchasing a listed item, the User warrants
        that:
        a) the   User   has   read   the   full   item   listing
        before making the purchase,
        b) the User accepts the terms and price that
        apply for the shipping of the purchased
        item; and
        c) The User accepts the terms that apply for
        the payment method of the purchased item.
        d) some   Services   require   you   to   enter   a
        separate agreement with a third party and
        such third party will determine if, and to
        what extent, you will be allowed to access
        such services;
        5.2 By purchasing a listed item, the User agrees
        to let HSS create an Account for the User,
        using the information provided by the User
        when   making   the   purchase,  which   can   be
        activated by the User at a later time. The User
        also agrees to let HSS share the information
        given by the User when making the purchase,
        including   personal   data,   to   the   Connected
        Manufacturer   who   originally   manufactured
        the Item, to be used for business- and product
        development   and   analyze,   statistics,
        extrapolation and marketing purposes. 
        5.3 Upon   making   a   purchase,   the   User   shall
        receive   the   tracking   information   for   the
        purchased item from HSS. 
        5.4 The User accepts that a purchased item may
        be   delivered   to   a   delivery   office   or   other
        delivery hub, rather than to the Users home
        address. 
        5.5 The User undertakes to collect all purchased
        items from the delivery office or delivery hub
        within the announced time. Should the User
        not collect an item, said item will be returned
        to the seller of the item, whereupon the User
        who   purchased   the   item   must   submit   a
        request for refund. HSS service fee, 10 % of
        the purchase price, and cost of the return
        shipping is not refunded.   
        5.6 Upon receiving/collecting the purchased item,
        the User undertakes to examine the item and
        immediately report any defects, or deviations
        from the item description in the listing, to
        HSS and the seller of the item.  If the User
        does   not   submit   the   complaint   within   five
        business days from the reception/collections
        of the purchased item, the User loses its right
        to any claims regarding the purchased item.

        <Subtitle>6. FEES AND PAYMENT</Subtitle>

        6.1 Unless otherwise agreed, HSS shall receive a
        fee of 10 % of the purchase price for any item
        sold by the User via the Services.  
        6.2 Unless   otherwise   agreed,   the   Connected
        Manufacturer of an item shall have the right
        to charge a fee of up to 10 % of the purchase
        price for such item sold by the User via the
        Services, provided that the applicable fee is
        clearly displayed in the Webshop or the App
        when making the listing for the item.  
        6.3 The   User   approves   that   HSS   engages
        external payment providers to enable the User
        to make and receive payment for items listed
        through the Services. The User undertakes to
        review and approve any separate terms given
        by such external payment providers. 
        6.4 The User agrees to let the external payment
        provider hold each payment for listed items
        for a duration of five business days from the
        delivery of such listed item to the buyer. If the
        buyer submits no complaints regarding the
        sold   item,   the   external   payment   provider
        releases the payment to the User. The User
        further agrees that any fees due to HSS are
        subtracted from the payment and transferred
        directly from the external payment provider to
        HSS.

        <Subtitle>7. RETURNS AND REFUNDS</Subtitle>

        7.1 If an item purchased by the User via the
        Services   does   not   comply   with   the   item
        description in the listing, the User may return
        the item to the seller for a refund (shipping
        costs are not refunded), provided that the
        User   submits   the   complaint,   and   presents
        photographic   evidence   to   show   the   non-
        conformity   of   the   item,   no   later   than  five
        business days from the reception/collection of
        the   item.   If   the   complaint   and   the
        photographic evidence is not submitted within
        said five business days, the User shall be
        deemed to have forfeit its right to return the
        item and receive a refund.
        7.2 HSS shall give the User who purchased the
        item instructions on how to return the item to
        the seller in an acceptable and traceable way.
        7.3 The User who purchased the item must return
        it no later than five business days after being
        provided with a shipping code from HSS. If
        the   item   is   not   shipped   within   said   five
        business days, the User shall be deemed to
        have forfeit its right to return the item and
        receive a refund. 
        7.4 HSS shall have the right to invoice the User
        who sold an item for the cost of the return
        shipping of said item  as  well as  for HSS
        service fee, 10 % of the purchase price, should
        the returned item not comply with the item
        description in the listing. 

        <Subtitle>8. HISTORIC PURCHASES</Subtitle>

        8.1 The User is aware and agrees to give HSS
        access to the User’s historic purchases from
        Connected Manufacturers, the Webshop, and
        the App (“Historic Purchases”).
        8.2 The User agrees to let HSS and Connected
        Manufacturers display Historic Purchases, in
        an anonymized way, to other users in the
        Services,   e.g.   through   searches   and/or
        matching,   and   to   let   other   users   make
        unsolicited offers for such Historic Purchases
        through the Services.

        <Subtitle>9. AVAILABILITY OF THE SERVICES</Subtitle>

        9.1 HSS shall strive to keep the Services running
        and   available   to   the   User.   However,   HSS
        cannot   guarantee   that   the   Services   will
        function   flawlessly   and   continuously.   The
        Services   may   be   disrupted   by   factors   and
        circumstances   (e.g.   technical   disruptions,
        faults in the User&apos;s digital device or network,
        viruses) which are beyond HSS’ control and
        for which HSS cannot be held responsible. 
        9.2 HSS also reserves the right, when required for
        maintenance, technical- or safety reasons, to
        take measures that may temporarily affect the
        availability of the Services. In certain cases,
        when the use of the Services risks seriously
        damaging   HSS,   HSS   has   the   right   to
        immediately block the User&apos;s access to the
        Services.   In   the   event   that   access   to   the
        Services is blocked or restricted in any way,
        the User shall be notified of the measures as
        soon as possible.

        <Subtitle>10. REMOVED PICTURES, LISTINGS, SUSPENSION AND TERMINATION</Subtitle>

        10.1 HSS has the right to remove pictures,
        listings, suspend the provision of the Services,
        and   terminate   the   User&apos;s   Account   with
        immediate effect, in the event that the User:
        a) defaults on its payments,
        b) breaches the Terms,
        c) uses   the   Services   in   an   unintended   or
        improper way, and/or 
        d) breaches any applicable law or infringes on
        any rights of a third party.
        10.2 A   decision   on   removing   a   listing,
        suspending or restricting the User’s use of the
        Services,   and/or   terminating   the   User’s
        Account, shall be communicated to the User,
        alongside a statement of the reasons for the
        decision, upon request.

        <Subtitle>11. FORCE MAJEURE</Subtitle>

        11.1 A Party shall be exempt from liability for
        breach of the Terms and / or sanction as a
        result of failure to fulfill obligations under the
        Terms due  to circumstance  that  hinder  or
        significantly   impede   or   delay   performance.
        Such a circumstance is, for example, war,
        terrorist attack, authority regulation, action or
        omission, labor market conflict (even where
        the Party does not itself participate in the
        conflict), blockade, fire, restrictions on energy
        supply,   flood   or   other   natural   disaster   or
        accident   of   greater   magnitude   or   other
        circumstances  outside  of a  Party’s  control,
        which the Party reasonably could not have
        foreseen and whose consequences the Party
        could   not   have   reasonably   avoided   or
        overcome. 
        11.2 A Party wishing to invoke such a force
        majeure condition shall without delay inform
        the counterparty in writing of the occurrence
        or cessation of the force majeure condition.

        <Subtitle>12. DATA MANAGEMENT</Subtitle>

        12.1 HSS   treats   all   User   information   as
        confidential.   However,   HSS   may   disclose
        confidential   information   to   relevant
        authorities, if so, obliged by court order or a
        corresponding authority decision. In cases of
        suspicions   against   the   User   regarding   of
        criminal   conduct,   HSS   may   disclose
        information at the request of the authorized
        investigative authority, or voluntarily report
        the   suspicion   of   criminal   conduct   to   the
        authorized investigative authority and provide
        such authority with the necessary information
        for the investigation. HSS may also disclose
        the User&apos;s information if this is necessary to
        safeguard HSS’ rights vis-à-vis the User.
        12.2 Credit card information is not stored in
        HSS’ e-commerce platform but is transferred
        directly to the payment systems connected to
        the Services. In such payment systems, all
        sensitive information is stored in encrypted
        form.
        12.3 HSS   may   process   the   User’s   personal
        data   when   providing   the   Services.   By
        accepting the Terms, the User approves HSS’
        processing   of   the   User’s   personal   data   in
        accordance with HSS’ privacy policy.

        <Subtitle>13. INTELLECTUAL PROPERTY RIGHTS</Subtitle>

        13.1 HSS exclusively owns all rights, including
        intellectual property rights, to the Services,
        including but not limited to trademarks, trade
        names, designs, texts, software etc., except
        for certain rights owned by HSS’ licensors.
        Nothing in the terms shall be construed as any
        of   these   rights,   wholly   or   partly,   being
        transferred   to   the   User.   All   copying,
        modification, transfer, licensing and/or other
        use of the Services is prohibited.
        13.2 The User understands that unauthorized
        use   of   HSS’   intellectual   property   rights
        constitutes   a   breach   of   contract   and   may
        otherwise constitute a criminal act. HSS has
        the right to take legal action against the User
        in the event of such unauthorized use of HSS’
        intellectual property rights. 
        13.3 The User grants HSS the right to use all
        content provided by the User, for commercial
        purposes.   “Content”   shall   in   this   regard
        include   all   information   (including,   but   not
        limited to, photographs, images, texts, words,
        videos,   data,   concepts,   ideas,   technology,
        databases and trademarks), provided by the
        User when using the Services.
        13.4 The User also grants HSS an unlimited,
        non-exclusive, irrevocable, worldwide, royalty-
        free, fully transferable and fully sublicensable
        right to use, copy, reproduce, alter, process,
        translate,   transfer,   make   available   and/or
        communicate such content for the purposes
        decided by HSS. The rights granted according
        to this provision shall, to the extent permitted
        by law, also include the User’s moral rights to
        the content.
        13.5 The   User   warrants   that   all   content
        provided  by  the  User  is  accurate  and  not
        infringing on any intellectual property right of
        any third party.

        <Subtitle>14. LIMITATION OF LIABILITY</Subtitle>

        14.1 HSS   exercises   no   control   over   the
        transactions   that   take   place   on   HSS’   e-
        commerce platform on behalf of the User. HSS
        is therefore not liable for direct or indirect
        damages and financial losses due to errors,
        delays,   imperfections,   interruptions,   non-
        delivery in the form of data, lack of availability
        or   similar   circumstances   or   events   in
        connection with transactions on behalf of the
        User.
        14.2 HSS   shall   not   be   held   liable   for   the
        communication   that   takes   place   between
        users,   for   information   that   the   User
        documents or the User&apos;s actions or conduct in
        general within the Services.
        14.3 HSS takes no credit risk or responsibility
        for transactions made by the User via the
        Services.
        14.4 HSS   shall   not   be   held   liable   for
        interruptions or errors in the Services caused
        by third-party systems. 
        14.5 HSS is not liable for damages that are
        caused by a loss of information for the User.
        14.6 HSS shall not be held liable with regard
        to costs, losses or other damage incurred to
        the User which are due to the User’s use of
        third party software, hardware, systems and
        similar   together   with   the   Services,   or   for
        viruses   or   other   security   interference,
        provided that HSS has implemented security
        measures   in   accordance   with   any   agreed
        requirements   or,   in   the   absence   of   such
        requirements, in accordance with professional
        standards.
        14.7 The   User&apos;s   right   to   compensation   for
        damages according to the Terms shall under
        all circumstances be limited to direct damages
        and to an amount corresponding to the Users
        total cost for the use of the Services. The
        limitation of liability also applies in relation to
        the   User&apos;s   possible   liability   towards   third
        parties. 
        14.8 A request for compensation from the User
        must be made to HSS within a reasonable
        time from the time the User’s suffered damage
        was   discovered   or   should   have   been
        discovered (normally within 30 days). If the
        request is not made within reasonable time,
        the User loses its right to compensation. 
        14.9 The User is  not entitled to any  other
        remedies or remunerations than those stated
        in the Terms.

        <Subtitle>15. CONSUMER RIGHTS</Subtitle>

        15.1 The Terms are not intended to limit or
        amend any of the User’s specific rights under
        Swedish consumer legislation. 
        15.2 Should the User also be a consumer, the
        User shall not be subject to any obligations in
        the Terms that are greater than those imposed
        on   the   User   under   mandatory   consumer
        legislation   and   rules   and   regulations.   This
        applies, among other things, to the indemnity-,
        limitations of liability-, and dispute resolution
        provisions of the Terms.

        <Subtitle>16. APPLICABLE LAW AND DISPUTES</Subtitle>

        16.1 The Agreement between the User and
        HSS is governed by Swedish law.
        16.2 Disputes that arise in connection with the
        Agreement   shall   primarily   be   resolved   by
        amicable   agreement.   If   the   Parties   cannot
        reach such an agreement within a reasonable
        time, the dispute may be referred to Allmänna
        Reklamationsnämnden (&quot;ARN&quot;), provided that
        ARN is authorized to examine the dispute, and
        may finally be resolved by Swedish general
        court, with the district court of Gothenburg as
        the first instance.

      </Typography>
    </CopyContainer>
  );
}
