import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SvgImg from '../../../components/SvgImg';
import Typography from '../../../components/Typography';
import { colors } from '../../../theme';
import PurchaseButton from './PurchaseButton';
import useItem from './useItem';
import { partner, template, useIsSmallScreen } from './utils';

const StyledTitleContainer = styled.div<{ isSmallScreen: boolean; }>(({ isSmallScreen }) => `
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  .subtitle {
    text-transform: uppercase;
  }
  .title {
    margin-right: 20px;
  }
  .ref {
    color: ${colors.textSecondary};
  }
  ${isSmallScreen ? `
    flex-direction: column;
    .title {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  ` : ''}
`);

export default function TitleContainer () {
  const { item } = useItem();
  const isSmallScreen = useIsSmallScreen();
  const { t, i18n } = useTranslation();
  const brand = item?.brand ?? item?.raw_data?.brand;
  return (
    <StyledTitleContainer isSmallScreen={isSmallScreen} className='title-container'>
      {template == 'outnorth' && (<>
        {item?.listing.condition == 'new' && (
          <Typography variant='detail-condition'>{t(`item.condition.${item.listing.condition}`)}!</Typography>
        )}
        <SvgImg
          className='second-hand'
          src='assets/icons/secondhand.svg'
          width={25}
        />
      </>)}
      {!!brand && <Typography variant='detail-brand' className="subtitle">{brand}</Typography>}
      <Typography variant='detail-name' className='title'>{item?.title}</Typography>
      {partner == 'stjarnurmakarna' && item?.partner_product_id && (
        <Typography variant='detail-body1' className='ref'>{t('item.ref', { val: item.partner_product_id })}</Typography>
      )}
      <Typography variant='detail-price'>{t('item.detailPrice', { val: item?.listing.price })}</Typography>
      {i18n.exists('checkout.storePickupDescription') && (
        <Typography style={{ marginTop: '8px' }} variant='recrql-body-medium'>
          {t('checkout.storePickupDescription')}
        </Typography>
      )}
      {partner == 'stjarnurmakarna' && (
        <PurchaseButton />
      )}
    </StyledTitleContainer>
  );
}
