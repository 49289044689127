import React, { useContext, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import AuthContainer from './AuthContainer';
import Button from '../../components/Button';
import { useSearchParams } from '../../utils/useSearchParams';
import LayoutContext from '../../contexts/LayoutContext';
import { useNavigate } from 'react-router-dom';
import Form, { FormFieldState, formIsValid, formObject } from '../../components/Form';
import { resetPasswordConfirm } from '../../api/users/endpoints';
import recrqlColors from '../../theme/recrql/colors';
import Typography from '../../components/Typography';

const useStyles = createUseStyles({
  resetPassword: {
    width: '100%',
  },
});

type FormBody = {
  password: string;
  password2: string;
}
export default function ResetPasswordView() {
  const styles = useStyles();
  const [{ id, token }] = useSearchParams<{ id: string, token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setLayoutContext] = useContext(LayoutContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!id || !token) {
      navigate('/login');
    }
    setLayoutContext(p => ({ ...p, handleGoBack: () => navigate('/login') }));
    return () => setLayoutContext(p => ({ ...p, handleGoBack: undefined }))
  }, []);
  const [form, setForm] = useState<FormFieldState<FormBody>>({
    password: { helperText: () => t('user.passwordHelperText'), type: 'password', isRequired: false, label: t('forgotPassword.newPassword'), value: '', isValid: (value) => value.length >= 8 },
    password2: {
      type: 'password',
      isRequired: false,
      label: t('forgotPassword.confirmPassword'),
      value: '',
      isValid: (value, state) => value.length >= 8 && state.password.value === value,
      isError: (value, state) => value.length >= 8 && state.password.value !== value,
      helperText: (isError) => isError ? t('user.validationError.nonMatchingPasswords') : ''
    }
  });
  const validForm = useMemo(() => formIsValid(form), [form]);
  const [error, setError] = useState('');
  async function handleResetPassword() {
    setIsLoading(true);
    const [_, error] = await resetPasswordConfirm(id, {
      token,
      password: form.password.value
    });
    setIsLoading(false);
    if (error) {
      if (error === 404) {
        setError(t('forgotPassword.userIdNotFound'))
      } else {
        const { token, password, detail } = error;
        setError(token?.at(0) ?? password?.at(0) ?? detail ?? t('forgotPassword.unknownError'));
      }
    } else {
      navigate('confirm');
    }
  }
  return (
    <AuthContainer title={t('forgotPassword.resetPassword')}>
      <div className={styles.resetPassword}>
        <Form<FormBody>
          state={form}
          onChange={setForm}
        />
        {error && (
          <Typography variant='recrql-label-medium' style={{ color: recrqlColors.error.background }}>
            {error}
          </Typography>
        )}
        <Button
          buttonStyle={{
            margin: '40px 0',
          }}
          type='primary'
          context='recrql'
          size='stretch'
          onClick={handleResetPassword}
          text={isLoading ? t('button.saving') : t('button.save')}
          isLoading={isLoading}
          state={isLoading||!validForm ? 'disabled' : 'active'}
        />
      </div>
    </AuthContainer>
  );
}
