import React from 'react';
import { ReactComponent as ReCRQLSVG } from '../assets/reCRQL.svg';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import styled from 'styled-components';
import { properties, styles } from '../theme';

export type LogoSize = 'small'|'medium'|'large';

const ReCRQLSizes: { [key in LogoSize]: number; } = {
  'small': 64,
  'medium': 94,
  'large': 120,
};

export type LogoProps = {
  recrqlSize: LogoSize;
  partnerSize: LogoSize;
  className?: string;
}

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  &.top-logo-container > :last-child {
    margin-left: 8px;
  }
  &.bottom-logo-container > :last-child {
    margin-left: 62px;
  }
  &.combined-logo-container > :last-child {
    margin-left: 20px;
  }
  ${styles.logo}
`;

export function CombinedLogo ({
  partnerSize,
  recrqlSize,
  className,
}: LogoProps) {
  return (
    <LogoContainer className={[
      'combined-logo-container',
      className
    ].join(' ')}>
      <PartnerLogo size={partnerSize} className='partner' />
      <ReCRQLLogo size={recrqlSize} className='recrql' />
    </LogoContainer>
  );
}

export const ReCRQLLogo = styled(ReCRQLSVG)<{ size: LogoSize; }>(({ size }) => `
  width: ${ReCRQLSizes[size]}px;
  height: auto;
`);

export const PartnerLogo = styled(LogoSVG)<{ size: LogoSize; }>(({ size }) => `
  width: ${properties.logoSizes[size]}px;
  height: auto;
`);

export function BottomLogo () {
  return (
    <LogoContainer className='bottom-logo-container'>
      <PartnerLogo size='small' className='partner' />
      <ReCRQLLogo size='small' className='recrql' />
    </LogoContainer>
  );
}
/*
recrql
64 = small
94 = medium
120 = large

houdini
118 = small
136 = large

su
164 = small
292 = large
*/
