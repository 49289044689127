import React, { Dispatch, SetStateAction, useState } from 'react';
import { Auth } from '../utils/auth';

export interface AuthContextState {
  isAuthenticated?: boolean;
  auth?: Auth;
}

const defaultState: AuthContextState = {
};

const AuthContext = React.createContext<[AuthContextState, Dispatch<SetStateAction<AuthContextState>>]>([defaultState, ()=>({})]);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(defaultState);
  return (
    <AuthContext.Provider value={[state, setState]}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
