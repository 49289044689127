import { ItemFiltersState } from '../../views/items/filters/types';
import { ItemFilterFields, ItemSearchParams, isFilterKey } from '../items/types';
import { Params } from './types';

type ParamValue = string|number|undefined;
type FilterObj = { [key: string]: ParamValue[]; };
export interface SearchParams<TFilter extends FilterObj> {
  filter?: TFilter;
  search?: string;
  sort?: string;
}

export function getSearchParams<TFilter extends FilterObj>({ filter, search, sort }: SearchParams<TFilter>): Params {
  const params: Params = {};
  if (filter) {
    Object.keys(filter).forEach(filterKey => {
      params[filterKey + '__in'] = filter[filterKey].join('__');
    });
  }
  if (sort) {
    // Sort limited to one field due to non-allowed duplicate key. elasticsearch requires re-using the same key for each different field.
    // https://django-elasticsearch-dsl-drf.readthedocs.io/en/latest/basic_usage_examples.html?highlight=ordering#ordering
    // https://teamtreehouse.com/community/does-javascript-object-allow-for-duplicate-keys#:~:text=No%2C%20JavaScript%20objects%20cannot%20have,keys%20must%20all%20be%20unique.
    params['ordering'] = sort + '';
  }
  if (search) {
    params['search'] = search;
  }
  return params;
}

export function getUrlSearchParamsObject(urlSearchParams: URLSearchParams) {
  const params: {[key:string]:string} = {};
  for (const [key, value] of urlSearchParams.entries()) {
    params[key] = value;
  }
  return params;
}

/*
Format:
?filter[field]=value,value2&filter[field2]=value3
*/
export function queryToFilter(urlSearchParams: URLSearchParams): ItemSearchParams {
  const params = getUrlSearchParamsObject(urlSearchParams);
  const isp: ItemSearchParams = { filter: {} };
  Object.keys(params).filter(isFilterKey).forEach(key => {
    if(isp.filter) {
      const values = params[key].split(',');
      isp.filter[key] = values;
    }
  })
  return isp;
}

export function itemFiltersStateToFields (filtersState: ItemFiltersState): ItemFilterFields {
  const fields: ItemFilterFields = {};
  const keys = Object.keys(filtersState) as [keyof ItemFiltersState];
  keys.forEach(key => {
    const items = filtersState[key].items.filter(i => i.isChecked).map(i => i.label);
    if (items.length) {
      fields[key] = items;
    }
  });
  return fields;
}

export function filterFieldsToEntries (filter: ItemFilterFields): { [key: string]: string } {
  return Object.keys(filter).map(key => {
    return {
      [key]: filter[key]?.map(i => i+'').join(',') as string
    };
  }).reduce((p, c) => ({ ...p, ...c }), {});
}

export function updateURLSearchParams(old: URLSearchParams, obj: { [key: string]: string }): URLSearchParams {
  const oldObj = getUrlSearchParamsObject(old);
  const newObj = {
    ...oldObj,
    ...obj
  };
  return new URLSearchParams(newObj);
}
