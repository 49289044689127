import {
  Facets,
  PaginationParams,
} from '../common/types';
import {
  Item,
  ItemSearchParams,
  ItemsPaginationReturn,
} from './types';
import {
  searchItems as searchItemsEP,
} from './endpoints';
import { usePagination } from '../common/hooks';

export function searchItems(params: PaginationParams = { limit: 30, offset: 0 }, searchParams: ItemSearchParams): ItemsPaginationReturn {
  return usePagination<Item, Facets>(['items-search', params, searchParams], params, params => searchItemsEP(params, searchParams), false);
}
