import React from "react";
import Typography from "./Typography";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { colors, styles as themeStyles } from "../theme";
import styled from "styled-components";
import zIndexes from "../utils/zIndexes";
import { ClickAwayListener } from "@mui/material";

const StyledDropdown = styled.div.attrs(({ className }) => ({
  className: ["dropdown", className].join(" "),
}))`
  position: relative;
  .top {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .content {
    z-index: ${zIndexes.dropdownContent};
    background-color: ${colors.backgroundPrimary};
    position: absolute;
    width: 224px;
    padding: 22px;
    border: 1px solid ${colors.strokePrimary};
  }
  .item-container {
    margin: 7px 0px;
    .checkbox {
      align-items: center;
      text-align: left;
    }
  }
`;

type Props = {
  onToggle?: () => void;
  items: React.ReactElement[];
  label: string | React.ReactNode;
  initialValue?: boolean;
  topClassName?: string;
  className?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
  itemContainerStyle?: React.CSSProperties;
  isExpanded: boolean;
  withIcon?: boolean;
};

export default function Dropdown({
  label,
  onToggle,
  items,
  isExpanded,
  className,
  topClassName,
  contentClassName,
  contentStyle,
  itemContainerStyle,
  withIcon = true,
}: Props) {
  function handleChangeValue() {
    onToggle?.();
  }
  return (
    <ClickAwayListener onClickAway={() => isExpanded && onToggle?.()}>
      <StyledDropdown className={className}>
        <div
          className={["top", topClassName].join(" ")}
          onClick={handleChangeValue}
        >
          {typeof label === "string" ? (
            <Typography variant="general-button1">{label}</Typography>
          ) : (
            label
          )}
          {withIcon ? (
            isExpanded ? (
              <ExpandLess htmlColor={themeStyles.dropdownIconColor} />
            ) : (
              <ExpandMore htmlColor={themeStyles.dropdownIconColor} />
            )
          ) : null}
        </div>
        {isExpanded && (
          <div
            className={["content", contentClassName].join(" ")}
            style={contentStyle}
          >
            {items.map((item, index) => (
              <div
                key={"dd" + index}
                className="item-container"
                style={itemContainerStyle}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </StyledDropdown>
    </ClickAwayListener>
  );
}
