import React, { useState} from 'react';
import { Delete } from '@mui/icons-material';
import { createUseStyles } from 'react-jss';
import { deletePaymentMethod, updatePaymentMethod } from '../../api/payments/endpoints';
import { PaymentMethod as PaymentMethodType} from '../../api/payments/types';
import Touchable from '../../components/Touchable';
import Typography from '../../components/Typography';
import recrqlColors from '../../theme/recrql/colors';
import { ReactComponent as Alipay } from '../../assets/credit_card_types/alipay.svg';
import { ReactComponent as Amex } from '../../assets/credit_card_types/amex.svg';
import { ReactComponent as Diners } from '../../assets/credit_card_types/diners.svg';
import { ReactComponent as Discover } from '../../assets/credit_card_types/discover.svg';
import { ReactComponent as Elo } from '../../assets/credit_card_types/elo.svg';
import { ReactComponent as Hiper } from '../../assets/credit_card_types/hiper.svg';
import { ReactComponent as HiperCard } from '../../assets/credit_card_types/hipercard.svg';
import { ReactComponent as JCB } from '../../assets/credit_card_types/jcb.svg';
import { ReactComponent as Maestro } from '../../assets/credit_card_types/maestro.svg';
import { ReactComponent as Mastercard } from '../../assets/credit_card_types/mastercard.svg';
import { ReactComponent as Mir } from '../../assets/credit_card_types/mir.svg';
import { ReactComponent as Paypal } from '../../assets/credit_card_types/paypal.svg';
import { ReactComponent as Unionpay } from '../../assets/credit_card_types/unionpay.svg';
import { ReactComponent as Visa } from '../../assets/credit_card_types/visa.svg';
import { CircularProgress } from '@mui/material';

function BrandSVG({ brand, width, height }: { brand: string, width: string, height: string }) {
  switch (brand) {
    case 'alipay': return <Alipay width={width} height={height} />;
    case 'amex': return <Amex width={width} height={height} />;
    case 'diners': return <Diners width={width} height={height} />;
    case 'discover': return <Discover width={width} height={height} />;
    case 'elo': return <Elo width={width} height={height} />;
    case 'hiper': return <Hiper width={width} height={height} />;
    case 'hipercard': return <HiperCard width={width} height={height} />;
    case 'jcb': return <JCB width={width} height={height} />;
    case 'maestro': return <Maestro width={width} height={height} />;
    case 'mastercard': return <Mastercard width={width} height={height} />;
    case 'mir': return <Mir width={width} height={height} />;
    case 'paypal': return <Paypal width={width} height={height} />;
    case 'unionpay': return <Unionpay width={width} height={height} />;
    default: return <Visa width={width} height={height} />;
  }
}

type StyleProps = {
  isDefault: boolean;
};
const useStyles = createUseStyles<string, StyleProps>({
  paymentMethod: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontWeight: ({ isDefault }) => isDefault ? 'bold' : 'inherit',
  },
});

type Props = {
  onDelete: () => void;
  onUpdate: () => void;
  paymentMethod: PaymentMethodType;
};

export default function PaymentMethod({ paymentMethod, onDelete, onUpdate }: Props) {
  const styles = useStyles({ isDefault: paymentMethod.is_default });
  async function handleDeletePaymentMethod() {
    setIsLoading(true);
    await deletePaymentMethod(paymentMethod.id);
    setIsLoading(false);
    onDelete();
  }
  const [isLoading, setIsLoading] = useState(false);
  async function handleSetDefault() {
    if (paymentMethod.is_default) return;
    setIsLoading(true);
    await updatePaymentMethod({
      payment_method_id: paymentMethod.id,
      set_default: true,
    });
    setIsLoading(false);
    onUpdate();
  }
  return (
    <div className={styles.paymentMethod}>
      <Touchable isDisabled={isLoading} style={{
        display: 'flex',
        alignItems: 'center',
      }} onClick={handleSetDefault}>
        <BrandSVG width='52px' height='52px' brand={paymentMethod.card.brand} />
        <Typography variant='recrql-button-medium' className={styles.text}>
          **** {paymentMethod.card.last4} {paymentMethod.billing_details.name}, {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
        </Typography>
      </Touchable>
      <Touchable isDisabled={isLoading} onClick={handleDeletePaymentMethod}>
        <Delete
          sx={{
            fontSize: '16px',
            color: recrqlColors.error.background,
            position: 'relative',
            top: '4px',
            marginLeft: '8px',
          }}
        />
      </Touchable>
      {isLoading && (
        <CircularProgress
          size='12px'
          style={{ marginLeft: '8px' }}
        />
      )}
    </div>
  );
}
