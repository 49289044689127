import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ItemsTable from "./ItemsTable";
import {
  filterFieldsToEntries,
  itemFiltersStateToFields,
  queryToFilter,
} from "../../api/common/elasticSearch";
import ItemFilters from "./filters/ItemFilters";
import { ItemFiltersState } from "./filters/types";
import usePrevious from "../../utils/usePrevious";
import { Facets } from "../../api/common/types";
import styled from "styled-components";
import { useRestoreLayoutScrollPosition } from "../../hooks/useRestoreScrollPosition";

const StyledDiv = styled.div.attrs({
  className: "items-filter-table",
})`
  width: 100%;
`;

type Props = {
  defaultFilters?: { [key: string]: string[] };
  categories?: string[] | null;
};

export default function ItemsFilterTable({
  defaultFilters,
  categories,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const itemSearchParams = useMemo(
    () => queryToFilter(searchParams),
    [searchParams]
  );
  const [facets, setFacets] = useState<Facets | undefined>(undefined);
  const prevDefaultFilters = usePrevious(defaultFilters);
  useRestoreLayoutScrollPosition();
  function handleSetFiltersState(filtersState: ItemFiltersState) {
    const fields = itemFiltersStateToFields(filtersState);
    const entries = filterFieldsToEntries(fields);
    setSearchParams(new URLSearchParams(entries));
  }

  return (
    <StyledDiv>
      <ItemFilters
        facets={facets}
        itemSearchParams={itemSearchParams}
        onSetState={handleSetFiltersState}
        categories={categories}
      />
      <ItemsTable
        searchParams={{
          filter: {
            ...defaultFilters,
            ...itemSearchParams.filter,
          },
        }}
        onQuery={({ facets: newFacets }) => {
          if (facets && prevDefaultFilters == defaultFilters) return;
          setFacets(newFacets);
        }}
      />
    </StyledDiv>
  );
}
