import fetcher from '../fetcher';
import { Pagination } from './types';

export function fetchPaginationUrl<TReturn extends Pagination<TResult, TFacets>, TResult, TFacets = void>(url: string, authenticate: boolean): Promise<TReturn> {
  return fetcher({
    relativePath: false,
    path: url,
    options: { method: 'GET' },
    authenticate,
  })
}
