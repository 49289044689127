import React, { useState } from 'react';
import Typography, { TypographyVariant } from './Typography';
import Touchable from './Touchable';
import colors from '../theme/colors.json';
import { Icon } from '@mui/material';
import styled from 'styled-components';
import { properties, styles } from '../theme';

const ExpanderContainer = styled.div<{ isExpanded: boolean; }>(({ isExpanded }) => `
  background-color: ${isExpanded ? 'rgb(249, 249, 249)' : colors.backgroundPrimary};
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: rgb(249, 249, 249);
  }
  .title {
    padding: 21px 17px;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  .contents {
    padding: 0 20px 29px 20px;
    display: ${isExpanded ? 'block' : 'none'};
  }
  ${styles.expander ?? ''}
`);

export type ExpanderProps = {
  isExpanded: boolean;
  onToggle: () => void;
  title: string;
  contents: React.ReactNode;
  titleVariant?: TypographyVariant;
  className?: string;
};

export default function Expander({
  isExpanded,
  onToggle,
  title,
  contents,
  titleVariant,
  className,
}: ExpanderProps) {
  const expandedIcon = properties.layoutSettings.expander?.expandedIcon;
  const closedIcon = properties.layoutSettings.expander?.closedIcon;
  return (
    <ExpanderContainer isExpanded={isExpanded} className={[
      'expander',
      className
    ].join(' ')}>
      <Touchable onClick={onToggle}>
        <div className='title'>
          <Typography variant={titleVariant ?? 'general-button2'}>{title}</Typography>
          {isExpanded ? <ExpanderIcon icon={expandedIcon ?? 'remove'} /> : <ExpanderIcon icon={closedIcon ?? 'add_outlined'} />}
        </div>
      </Touchable>
      <div className='contents'>
        {contents}
      </div>
    </ExpanderContainer>
  );
}

const ExpanderIcon = ({ icon }: { icon: string; }) => {
  return (
    <Icon className='icon'>
      {icon}
    </Icon>
  )
}

export function AutoExpander(props: Omit<ExpanderProps, 'isExpanded'|'onToggle'>) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Expander
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded(p => !p)}
      {...props}
    />
  );
}
