import React from 'react';
import Breadcrumb from '../common/navigation/Breadcrumb';
import ItemsFilterTable from './ItemsFilterTable';
import ContentContainer from '../../components/ContentContainer';
import { useTranslation } from 'react-i18next';
import { properties, styles } from '../../theme';
import Typography from '../../components/Typography';
import styled from 'styled-components';
import { useIsMobile } from '../../utils/useWindowDimensions';

const themeStyles = styles.itemsView;

type Props = {
  title: string;
  defaultFilters?: any;
  categories: string[]|null|undefined;
}

const StyledTitle = styled(Typography)`
  ${themeStyles.title}
`;

const TopContainer = styled.div.attrs({
  className: 'top-container'
})<{ isMobile: boolean; }>(({ isMobile }) => `
  margin-bottom: ${isMobile ? '48px' : '60px'};
  ${themeStyles.topContainer}
`);

export default function ItemsView({ title, defaultFilters, categories }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <ContentContainer className='items-view'>
      <TopContainer isMobile={isMobile}>
        <Breadcrumb title={t(title)} />
        {properties.layoutSettings.itemsView.displayTitle && (
          <StyledTitle variant="general-h2">{t(title)}</StyledTitle>
        )}
      </TopContainer>
      <ItemsFilterTable
        defaultFilters={defaultFilters}
        categories={categories}
      />
    </ContentContainer>
  );
}
