export default {
  "recrql-label-medium": `
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
  `,
  "recrql-label-large": `
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  `,
  "recrql-title-medium": `
    font-family: Raleway;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
  `,
  "recrql-title-large": `
    font-family: Raleway;
    font-weight: 300;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  `,
  "recrql-title-extra-large": `
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-size: 36px;
    line-height: 28px;
  `,
  "recrql-button-medium": `
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center;
  `,
  "recrql-body-medium": `
    font-family: Spectral; 
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
  `,
  "recrql-label-thin": `
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
  `,
}
