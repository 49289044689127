import colors from './colors.json';
import { Styles } from './types';
import LogoCombined from '../assets/logo-combined.svg';

const styles: Styles = {
  "layout": ({ isMobile }) => `
    .items-view .top-container, .breadcrumb {
      display: none;
    }
    .filters-drawer > .MuiPaper-root {
      .filter-show-button {
        display: block;
      }
      .side-menu-title-container {
        margin: 0px;
        padding: 26.5px 17px;
      }
      .side-menu-title:after {
        display: none;
      }
    }
    .mobile-sublogo {
      background: none;
    }
    .items-filter-table {
      ${isMobile ? '' : `
        display: flex;
        >:first-child {
          width: 20%;
          min-width: 180px;
        }
        >:last-child {
          width: 80%;
        }
      `}
      .item-filters-desktop {
        flex-direction: column;
        margin-top: 30px;
      }
    }
    .carousel .counter-selector {
      display: none;
    }
    .home {
      padding: ${isMobile ? '40px 20px' : '40px 80px'};
      .home-title-line {
        height: 0px;
        display: none;
      }
    }
    .search-result .search-result-title-line {
      heght: 0px;
      display: none;
    }
    .search-drawer > .MuiPaper-root {
      background-color: ${colors.backgroundPrimary};
      width: 100%;
      position: absolute;
      height: 197px;
      margin-top: 0px;
      background-image: url(${LogoCombined});
      background-repeat: no-repeat;
      background-position: 24px 39px;
      .search-input {
        margin: 113px 24px;
        width: calc(100% - 78px);
      }
      .close-button {
        position: absolute;
        right: 24px;
        top: 36px;
        border-radius: 64.2857px;
        background: ${colors.backgroundSecondary};
        padding: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .search-input {
      width: 50%;
      padding: 12px 15px;
      border-radius: 4px;
      margin-left: 24px;
      input {
        background-color: rgba(0,0,0,0);
      }
    }
    .nav-right-user {
      margin-left: ${isMobile ? '15px' : '40px'};
    }
    .nav-desktop {
      padding: 24px 78px;
      >.logo-container {
        display: flex;
        align-items: center;
        .nav-logo-container {
          flex: 1;
        }
        .nav-right {
          flex: 0;
        }
      }
      .flex-container {
        border-bottom: 0px;
        padding: 0;
        margin-top: 24px;
        >:first-child {
          padding-left: 0px;
        }
        >.external-link-container {
          flex: 1;
          >.external-link {
            padding: 0px;
            float: right;
          }
        }
      }
    }
    .item-row {
      > .item-row-sub {
        align-items: flex-start;
        text-align: left;
        .sustainability-options-container {
          top: 0px;
          right: 0px;
        }
        .price-container > .typography-listing-price1 {
          color: ${colors.secondaryAccent};
        }
      }
      .item-row-title-container {
        display: flex;
        flex-direction: column;
        height: 74px;
        > .typography-listing-subtitle {
        }
        > .typography-listing-title {
          overflow: hidden;
          margin-bottom: 2px;
        }
      }
      >.item-row-sub:hover {
        background-color: ${colors.backgroundSecondary};
      }
      .img-container {
        > .img-placeholder {
          background-color: rgba(0,0,0,0);
        }
        > img {
          width: 100%;
          height: auto;
        }
      }
      .typography-listing-condition {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
      }
    }
    .usps {
      border-bottom: 1px solid ${colors.backgroundSecondary};
      padding: ${isMobile ? '12px' : '24px'};
      .label-icon {
        margin: 0 18px;
      }
    }
  `,
  "logo": `
    &.top-logo-container > :last-child {
      margin-left: 26px;
    }
    &.top-logo-container > .recrql {
      width: 106px;
    }
    &.bottom-logo-container > :last-child {
      margin-left: 17px;
    }
    &.bottom-logo-container > .recrql {
      width: 58px;
    }
  `,
  "nav": {
    "superContainer": ``,
    "desktopMainContainer": ``,
    "flexContainer": ``,
    "navLogo": ``,
    "linksContainer": ``,
  },
  "button": ``,
  "breadcrumbNavLink": ``,
  "itemsView": {
    "title": ``,
    "topContainer": ``
  },
  "carousel": ``,
  "itemFiltersDesktopView": ``,
  "dropdownIconColor": colors.primaryAccent,
  "itemDetail": ({ isSmallScreen }) => `
    .expanders {
      margin-top: 36px;
    }
    .title-container {
      flex-direction: column;
      align-items: flex-start;
      .typography-detail-price {
        color: ${colors.secondaryAccent};
      }
      .title {
        margin-bottom: 12px;
      }
      img.second-hand {
        position: absolute;
        right: 0px;
      }
      >.condition-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    .left {
      width: 64%;
    }
    .right {
      width: 36%;
      margin-left: 80px;
      .item-meta {
        padding-inline-start: 1em;
      }
    }
    .seller-container {
      background-color: ${colors.backgroundSecondary};
      padding: 18px;
      width: calc(100% - 36px);
      :first-child {
        margin-bottom: 8px;
      }
    }
    ${isSmallScreen ? `
      .seller-container {
        align-items: flex-start;
      }
      .title-container, .item-meta, .left, .right {
        width: 100%;
      }
      .right {
        margin-left: 0px;
        align-items: normal;
      }
    ` : ''}
  `,
  "divider": ``,
}

export default styles;
