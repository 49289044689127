import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrollPositions: Record<string, number> = {};

export const useRestoreLayoutScrollPosition = () => {
  const scrollableDiv = document.getElementById("layout");
  const location = useLocation();
  useEffect(() => {
    if (!scrollableDiv) return;
    setTimeout(() => {
      if (scrollPositions[`${location.pathname}`] !== undefined) {
        scrollableDiv.scrollTo(0, scrollPositions[`${location.pathname}`]);
      }
    }, 0);

    const onScroll = () => {
      scrollPositions[`${location.pathname}`] = scrollableDiv.scrollTop;
    };

    scrollableDiv.addEventListener("scroll", onScroll);
    return () => {
      return scrollableDiv.removeEventListener("scroll", onScroll);
    };
  }, [scrollableDiv, location.pathname]);
};
