import React from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { Purchase } from '../../api/purchases/types';
import Typography from '../../components/Typography';

const useStyles = createUseStyles({
  orderInfo: {
  },
  row: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = {
  purchase: Purchase;
};

export default function OrderInfo({ purchase }: Props) {
  const styles = useStyles();
  return (
    <div className={styles.orderInfo}>
      <Typography variant='recrql-label-medium'>{}</Typography>
      <Row
        label={t('checkout.success.orderId')}
        value={purchase.id.substring(purchase.id.lastIndexOf('-') + 1)}
      />
      <Row
        label={t('checkout.success.orderDate')}
        value={moment().format('DD MMMM YYYY')}
      />
      <Row
        label={t('checkout.success.paymentMethod')}
        value={t('checkout.success.card')}
      />
      {!!purchase.listing_data && (
        <Row
          label={t('checkout.success.deliveryType')}
          value={t(`checkout.success.${purchase.listing_data.delivery_method ?? 'postnord'}`)}
        />
      )}
    </div>
  );
}

function Row({ label, value }: { label: string, value: string }) {
  const styles = useStyles();
  return (
    <div className={styles.row}>
      <Typography variant='recrql-label-medium'>{label}</Typography>
      <Typography variant='recrql-label-large'>{value}</Typography>
    </div>
  );
}
