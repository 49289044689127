import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RegistrationBody, RegistrationError } from '../../api/users/types';
import { register } from '../../api/users/endpoints';
import AuthContainer from './AuthContainer';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as auth from '../../utils/auth';
import LayoutContext from '../../contexts/LayoutContext';
import Form, { FormFieldState, formIsValid as formIsValidF, formObject } from '../../components/Form';
import Checkbox from '../../components/Checkbox';
import Typography from '../../components/Typography';
import LargeCheckbox from '../../components/LargeCheckbox';
import { Link } from 'react-router-dom';

type RegistrationForm = RegistrationBody & { password2: string };

export default function RegisterView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registrationForm, setRegistrationForm] = useState<FormFieldState<RegistrationForm>>({
    first_name: { isValid: value => !!value.length, value: '', name: 'fname', label: t('user.firstName') },
    last_name: { isValid: value => !!value.length, value: '', name: 'lname', label: t('user.lastName') },
    email: { isValid: value => !!value.length, value: '', name: 'email', label: t('user.mail'), type: 'email' },
    password: { isValid: value => value.length >= 8, value: '', name: 'password', label: t('user.password'), type: 'password', helperText: () => t('user.passwordHelperText') },
    password2: {
      isValid: (value, state) => value.length >= 8 && value == state.password.value,
      isError: (value, state) => value.length >= 8 && value !== state.password.value,
      helperText: (isError) => isError ? t('user.validationError.nonMatchingPasswords') : '',
      name: 'password2',
      value: '',
      label: t('user.repeatPassword'),
      type: 'password'
    },
  });
  const [error, setError] = useState<RegistrationError>();
  const [params] = useSearchParams();
  const redirectUri = params.get('redirectUri');
  async function handleRegister() {
    const registrationBody = formObject(registrationForm, null);
    if (!registrationForm || !registrationBody) return;
    setIsLoading(true);
    const [response, error] = await register(registrationBody);
    if (response) {
      const { tokens } = response;
      await auth.register(tokens);
      navigate(redirectUri ?? '/');
    } else if (error) {
      setError(error);
    }
    setIsLoading(false);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [_, setLayoutContext] = useContext(LayoutContext);
  useEffect(() => {
    setLayoutContext(p => ({
      ...p,
      handleGoBack: () => navigate('/'),
    }));
    return () => {
      setLayoutContext(p => ({
        ...p,
        handleGoBack: undefined,
      }));
    };
  }, []);
  function handleLogin() {
    navigate('/login');
  }
  const formIsValid = useMemo(() => formIsValidF(registrationForm), [registrationForm]);
  const [approvedTOS, setApprovedTOS] = useState(false);
  const canRegister = approvedTOS && !!registrationForm && formIsValid && !isLoading;
  return (
    <AuthContainer title={t('user.register')}>
      <Form<RegistrationForm>
        state={registrationForm}
        validationError={error}
        onChange={setRegistrationForm}
        onChangeError={setError}
      />
      <div style={{ margin: '35px 0 45px 0', display: 'flex', alignItems: 'center' }}>
        <Typography variant='recrql-label-thin' style={{ flex: 1 }}>
          Jag bekräftar att jag har läst och samtycker till&nbsp;
          <Link target="_blank" to="/tos" style={{ textDecorationLine: 'underline' }}>reCRQL:s användarvillkor.</Link>
        </Typography>
        <LargeCheckbox
          checked={approvedTOS}
          onChange={(e, checked) => setApprovedTOS(checked)}
        />
      </div>
      <Button
        context='recrql'
        onClick={handleRegister}
        state={canRegister ? 'active' : 'disabled'}
        size='stretch'
        text={t(isLoading ? 'user.registering' : 'user.register')}
        type='primary'
        isLoading={isLoading}
      />
      <Button
        context='recrql'
        onClick={handleLogin}
        state='active'
        size='stretch'
        text={t('user.logIn')}
        type='link'
      />
    </AuthContainer>
  )
}
