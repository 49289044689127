import React from "react";
import { Icon as MUIIcon } from "@mui/material";
import { properties } from "../theme";
import SvgImg from "./SvgImg";

type Props = {
  name: string;
};

export default function Icon({ name }: Props) {
  const isOutlined = name.endsWith("_outlined");
  if (properties.icons && name in properties.icons) {
    return <SvgImg src={properties.icons[name]} />
  }
  return (
    <MUIIcon className={isOutlined ? 'material-icons-outlined' : ''}>
      {name}
    </MUIIcon>
  );
}
