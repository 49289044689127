import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import PriceInfo from './PriceInfo';
import PaymentElement from './PaymentElement';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe, Stripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import LayoutContext from '../../contexts/LayoutContext';
import { getItemSubPath } from '../../theme/utils';
import { getItem } from '../../api/items/endpoints';
import { PurchaseRequest } from '../../api/purchases/types';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import recrqlColors from '../../theme/recrql/colors';
import * as localStorage from '../../utils/localStorage';
import Form, { FormFieldState, formIsValid as formIsValidF, formObject } from '../../components/Form';
import { CircularProgress } from '@mui/material';
import { useSearchParams } from '../../utils/useSearchParams';
import { getPaymentMethods } from '../../api/payments/queries';
import PaymentMethod from './PaymentMethod';
import { useIsMobile } from '../../utils/useWindowDimensions';
import { Item } from '../../api/items/types';
import AuthContext from '../../contexts/AuthContext';
import { ReCRQLLogo } from '../../components/Logo';
import AddressInfo from './AddressInfo';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useClientSecret from './useClientSecret';
import usePaymentHandler from './usePaymentHandler';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

type StyleProps = {
  isMobile: boolean;
};
const useStyles = createUseStyles<string, StyleProps>({
  right: {
    overflowY: 'scroll',
    flex: 1,
    padding: ({ isMobile }) => isMobile ? '20px' : '54px 6%',
    backgroundColor: recrqlColors.background.background,
    boxShadow: ({ isMobile }) => isMobile ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '-1px 0px 15px 5px rgba(0, 0, 0, 0.08)',
  },
  paymentMethods: {
    flex: 1,
  },
});

const PayButton = styled(Button)<{ isMobile: boolean; }>(({ isMobile }) => `
  .button {
    margin-top: 35px;
    margin-bottom: ${isMobile ? '36px' : 'unset'};
  }
`);

const StyledDiv = styled.div<{ isMobile: boolean; }>(({ isMobile }) => `
  height: 100%;
  display: ${isMobile ? 'inherit' : 'flex'};
  .left {
    overflow: scroll;
    flex: 1;
    padding: ${isMobile ? '20px' : '114px 85px 54px 85px'};
    display: flex;
    flex-direction: column;
    position: relative;
    > .left-sub {
      flex: 1;
    }
  }
  .left-bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${isMobile ? '52px 0' : 'unset'};
    margin-top: 16px;
    > svg:nth-of-type(2) {
      margin-top: 16px;
    }
  }
`);

type AddressFormBody = PurchaseRequest;

export default function ItemCheckout() {
  const isMobile = useIsMobile();
  const styles = useStyles({ isMobile });
  const { id } = useParams();
  const [_, setLayoutContext] = useContext(LayoutContext);
  const [pendingPurchase, setPendingPurchase] = useState<PurchaseRequest>(localStorage.getItem('pendingPurchase') ?? {
    delivery_first_name: '',
    delivery_last_name: '',
    delivery_street: '',
    delivery_care_of: '',
    delivery_city: '',
    delivery_postal_code: '',
  });
  useEffect(() => {
    localStorage.setItem('pendingPurchase', pendingPurchase);
  }, [pendingPurchase]);
  const navigate = useNavigate();
  const [item, setItem] = useState<Item>();
  const [{ auth, isAuthenticated }] = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    (async function() {
      try {
        const item = await getItem(id);
        setItem(item);
      } catch {
        navigate('/');
      }
    })();
    if (!stripe) {
      stripePromise.then(setStripe);
    }
    if (isMobile) {
      document.getElementById('layout')?.scrollTo({ top: 0 });
    }
    return () => setLayoutContext(p => ({ ...p, contentStyle: undefined }));
  }, []);
  useEffect(() => {
    if (isAuthenticated === true && auth && !pendingPurchase.delivery_first_name && !pendingPurchase.delivery_last_name) {
      setAddressFormState(p => ({
        ...p,
        delivery_first_name: { ...p.delivery_first_name, value: auth.me.first_name ?? '' },
        delivery_last_name: { ...p.delivery_last_name, value: auth.me.last_name ?? '' },
      }));
    } else if (isAuthenticated === false) {
      setAddressFormState(p => ({
        ...p,
        email: { ...p.email!, ignore: false },
        phone_number: { ...p.phone_number!, ignore: false },
      }));
    }
  }, [isAuthenticated, auth])
  useEffect(() => {
    setLayoutContext(p => ({
      ...p,
      contentStyle: {
        overflow: isMobile ? 'unset' : 'hidden',
      },
    }));
  }, [isMobile]);
  useEffect(() => {
    if (!item) return;
    setLayoutContext(p => ({
      ...p,
      handleGoBack: () => navigate(`/${getItemSubPath(item)}/${id}/`),
    }));
    return () => {
      setLayoutContext(p => ({
        ...p,
        backButtonDisabled: false,
        handleGoBack: undefined,
      }));
    };
  }, [item]);
  const [addressFormState, setAddressFormState] = useState<FormFieldState<AddressFormBody>>({
    delivery_first_name: { isValid: value => value.length >= 2, value: pendingPurchase.delivery_first_name, name: 'fname', position: 'left', label: t('user.firstName') },
    delivery_last_name: { isValid: value => value.length >= 2, value: pendingPurchase.delivery_last_name, name: 'lname', position: 'right', label: t('user.lastName') },
    delivery_street: { isValid: value => value.length >= 4, value: pendingPurchase.delivery_street, name: 'street', position: 'left', label: t('address.address')  },
    delivery_care_of: { isValid: () => true, value: pendingPurchase.delivery_care_of??'', name: 'careof', position: 'right', label: t('address.co'), isRequired: false },
    delivery_postal_code: {
      isError: value => value.length > 50,
      isValid: value => /^\d{2,}$/g.test(value),
      name: 'pcode',
      value: pendingPurchase.delivery_postal_code,
      position: 'left',
      label: t('address.zip'),
      formatValue: value => value.replaceAll(/[^\d]/g, ''),
    },
    delivery_city: { isError: value => value.length > 255, isValid: value => value.length > 2, name: 'city', value: pendingPurchase.delivery_city, position: 'right', label: t('address.city') },
    email: {
      isValid: value => value.length > 2,
      name: 'email',
      label: t('user.mail'),
      value: pendingPurchase.email ?? '',
      ignore: true
    },
    phone_number: {
      isValid: value => value.length >= 10,
      name: 'phonenumber',
      label: t('user.phoneNumber'),
      value: pendingPurchase.phone_number ?? '',
      ignore: true
    }
  });
  const formIsValid = useMemo(() => formIsValidF(addressFormState), [addressFormState]);
  useEffect(() => {
    setPendingPurchase(formObject(addressFormState, pendingPurchase) as PurchaseRequest);
  },[addressFormState]);
  const [{ payment_error, setup_intent_client_secret, payment_intent_client_secret }] = useSearchParams();
  const [paymentError, setPaymentError] = useState(payment_error);
  const clientSecret = useClientSecret(id ?? '0', setPaymentError);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => setLayoutContext(p => ({ ...p, backButtonDisabled: isLoading })), [isLoading]);
  const [stripe, setStripe] = useState<Stripe|null>(null);
  const [stripeElements, setStripeElements] = useState<StripeElements|null>(null);
  const [stripePaymentElement, setStripePaymentElement] = useState<StripePaymentElement>();

  // Payment
  const paymentMethods = getPaymentMethods();
  const [newPaymentMethodFormIsOpen, setNewPaymentMethodFormIsOpen] = useState(false);
  const [paymentElementIsComplete, setPaymentElementIsComplete] = useState(false);

  const canPay = id && stripe && formIsValid && ((isAuthenticated === false && paymentElementIsComplete) || paymentMethods.count > 0);
  const showNewPaymentForm = (isAuthenticated === false || newPaymentMethodFormIsOpen || !!setup_intent_client_secret || (paymentMethods.isFetched && paymentMethods.count == 0));
  function getIsLoadingPayment() {
    return !payment_intent_client_secret && !paymentError && ((paymentMethods.isFetched && paymentMethods.isLoading) || 
    (showNewPaymentForm && !stripePaymentElement) || !!setup_intent_client_secret)
  }
  const [isLoadingPayment, setIsLoadingPayment] = useState(getIsLoadingPayment());
  useEffect(() => {
    setIsLoadingPayment(getIsLoadingPayment());
  }, [paymentMethods.isFetched, paymentError, showNewPaymentForm, setup_intent_client_secret, stripePaymentElement]);

  const paymentHandler = usePaymentHandler(stripe, stripeElements, item, pendingPurchase, setIsLoading, setPaymentError);

  function handleClickPay() {
    if(isAuthenticated === true) {
      paymentHandler.accountPurchase(paymentMethods.data);
    } else {
      paymentHandler.accountlessPurchase();
    }
  }

  return (
    <StyledDiv isMobile={isMobile}>
      <div className='left'>
        <div className='left-sub'>
          {!!item && <PriceInfo context='checkout' item={item} />}
          {!!item && (<div style={{ marginTop: '32px' }}>
            {item.listing.delivery_method == 'in_store' ? (<>
              <AddressInfo
                title={t('address.storeAddress')}
                name={item.listing.seller_first_name}
                street={item.listing.retailer_address?.street}
                postalCode={item.listing.retailer_address?.postal_code}
                city={item.listing.retailer_address?.city}
              />
              {i18n.exists('checkout.storePickupDescription') && (
                <Typography style={{
                  marginTop: '16px',
                  display: 'block',
                  textAlign: 'left',
                }} variant='recrql-body-medium'>
                  {t('checkout.storePickupDescription')}
                </Typography>
              )}
            </>) : (
              <AddressInfo
                title={t('item.seller')}
                name={item.listing.seller_first_name}
              />
            )}
          </div>)}
        </div>
        {!isMobile && <Bottom />}
      </div>
      <div className={styles.right}>
        <div className={styles.address}>
          <Typography variant='recrql-title-medium' style={{ marginBottom: '16px', display: 'flex' }}>{t('checkout.shippingInformation')}</Typography>
          <Form<AddressFormBody>
            state={addressFormState}
            onChange={setAddressFormState}
          />
        </div>
        <Typography variant='recrql-title-medium' style={{ margin: '16px 0', display: 'flex' }}>
          {t('checkout.payment')}
        </Typography>
        {paymentMethods.count > 0 && (
          <div className={styles.paymentMethods}>
            {paymentMethods.data.map(paymentMethod => (
              <PaymentMethod
                onDelete={()=>{
                  paymentMethods.refetch();
                }}
                onUpdate={() => {
                  paymentMethods.refetch();
                }}
                key={'payment-method-' + paymentMethod.id}
                paymentMethod={paymentMethod}
              />
            ))}
            {!newPaymentMethodFormIsOpen && (
              <Button
                buttonStyle={{
                  padding: '10px 0',
                  justifyContent: 'unset',
                }}
                state='active'
                type='link'
                size='medium'
                text={t('button.addNew')}
                context='recrql'
                onClick={()=> {
                  setNewPaymentMethodFormIsOpen(true);
                }}
              />
            )}
          </div>
        )}
        {!!clientSecret && !!id && (
          <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
            {showNewPaymentForm && (
              <div style={{ marginTop: '8px' }}>
                <PaymentElement
                  id={id}
                  onReady={setStripePaymentElement}
                  onCreate={() => paymentMethods.refetch()}
                  onChange={(isComplete) => setPaymentElementIsComplete(isComplete)}
                  onElements={setStripeElements}
                />
              </div>
            )}
          </Elements>
        )}
        {paymentError && (
          <Typography variant='recrql-label-medium' style={{ color: recrqlColors.error.background }}>
            {paymentError}
          </Typography>
        )}
        {isLoadingPayment && (
          <CircularProgress
            size={24}
            style={{ color: recrqlColors.neutralGrey.background }}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <Checkbox
            style={{ marginTop: '47px' }}
            isChecked={false}
            labelComponent={<Typography variant='recrql-label-medium'>{t('checkout.subscribeToNewsLetter', { partner: partner.name })}</Typography>}
          /> */}
          <Typography style={{ margin: '61px 0 8px 0' }} variant='recrql-label-medium'>{t('checkout.totalIncludingVAT')}</Typography>
          <Typography variant='recrql-title-extra-large'>{t('item.price', { val: item?.listing.total_price })}</Typography>
        </div>
        <PayButton
          isLoading={isLoading}
          state={canPay&&!isLoading ? 'active' : 'disabled'}
          text={t('checkout.pay')}
          type='primary'
          context='recrql'
          onClick={handleClickPay}
          size='stretch'
          isMobile={isMobile}
        />
      </div>
      {isMobile && <Bottom />}
    </StyledDiv>
  );
}

function Bottom() {
  const { t } = useTranslation();
  return (
    <div className='left-bottom'>
      <Typography variant='recrql-label-medium'>{t('checkout.poweredByStripe')}</Typography>
      <ReCRQLLogo size='medium' />
    </div>
  );
}
