import {
  Facets,
  Pagination,
  PaginationParams,
} from '../common/types';
import { Item, ItemSearchParams, MyItem } from './types';
import fetcher from '../fetcher';
import properties from '../../theme/properties.json';
import { getSearchParams } from '../common/elasticSearch';

export function getItem(id?: Item['id']): Promise<Item> {
  const { partner } = properties;
  return fetcher({
    path: `/items-search/${partner.slug}/listed-item/${id}/`,
    options: { method: 'GET' },
    authenticate: false,
  });
}

export function searchItems(params: PaginationParams, searchParams: ItemSearchParams): Promise<Pagination<Item, Facets>> {
  const { partner } = properties;
  searchParams = getSearchParams(searchParams);
  return fetcher({
    path: `/items-search/${partner.slug}/listed-item/`,
    options: { method: 'GET' },
    authenticate: false,
    params: { ...params, ...searchParams },
  });
}
