import * as tokenHandler from '../utils/auth';
import { Params } from './common/types';

let hostUrl = process.env.REACT_APP_API_HOST ?? '';
const isAbsoluteHost = /^(?:[a-z+]+:)?\/\//i.test(hostUrl); // https://regex101.com/r/HyLste/1
if (!isAbsoluteHost) {
  hostUrl = `${window.location.protocol}//${window.location.host}${hostUrl}`; 
}

export interface FetcherOptions {
  path: string;
  relativePath?: boolean;
  params?: Params;
  options: RequestInit;
  authenticate: boolean;
  accessToken?: string;
}

export default async function fetcher<TResponse>({
  path,
  params,
  options,
  authenticate,
  accessToken,
  relativePath = true,
}: FetcherOptions): Promise<TResponse> {
  let url: URL
  if (relativePath) {
    url = new URL(hostUrl + path);
    if (params) {
      url.search = new URLSearchParams(params).toString();
    }
  } else {
    url = new URL(path);
  }
  options.headers = {
    ...options.headers,
    'Content-Type': 'application/json',
  }
  if (authenticate) {
    if (!accessToken) {
      const { tokenPair: { access } } = await tokenHandler.authenticate()
      accessToken = access;
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  const r = await fetch(url.toString(), options);
  let j: TResponse|null = null;
  try {
    j = await r.json();
  } catch {
    //
  }
  if (r.status >= 400) {
    throw j ?? r.status;
  }
  return j ?? {} as TResponse;
}

export async function fetchErrHandler<TResponse, TError>(request: () => Promise<TResponse>): Promise<[TResponse?, TError?]> {
  let res: TResponse|undefined;
  let err: TError|undefined;
  try {
    const r = await request();
    res = r;
  } catch (e) {
    if (e as TError) {
      err = e as TError;
    }
  }
  return [res, err];
}
