import colors from './colors.json';
export default {
  "general-h1": `
    font-family: Titillium Web;
    font-size: 28px;
    font-weight: 700;
  `,
  "general-h2": `
    font-family: Titillium Web;
    font-size: 24px;
    font-weight: 700;
  `,
  "general-h3": `
    font-family: Titillium Web;
    font-size: 20px;
    font-weight: 700;
  `,
  "general-body1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "general-body2": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "general-button1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "general-button2": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "general-subtitle1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "general-subtitle2": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "detail-subtitle1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "detail-subtitle2": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "general-link": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  `,
  "detail-h1": `
    font-family: Titillium Web;
    font-size: 24px;
    font-weight: 700;
  `,
  "detail-description": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "detail-body1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "detail-body2": `
    font-family: Titillium Web;
    font-size: 14px;
    font-weight: 400;
  `,
  "detail-name": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "detail-brand": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "detail-price": `
    font-family: Titillium Web;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    letter-spacing: 0.005em;
  `,
  "detail-ogprice": `
    font-family: Titillium Web;
    font-size: 24px;
    font-weight: 700;
    text-decoration: line-through;
    line-height: 29px;
    letter-spacing: 0.005em;
  `,
  "detail-condition": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.secondaryAccent};
  `,
  "detail-link": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  `,
  "nav-button1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "nav-button2": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "nav-subtitle1": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "nav-body1": `
    font-family: Titillium Web;
    font-size: 12px;
    font-weight: 400;
  `,
  "nav-search": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  Body2: `
    font-family: Titillium Web;
    font-size: 12px;
    font-weight: 600;
  `,
  "listing-title": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
  "listing-subtitle": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
  `,
  "listing-price1": `
    font-family: Titillium Web;
    font-size: 20px;
    font-weight: 700;
  `,
  "listing-price2": `
    font-family: Titillium Web;
    font-size: 20px;
    font-weight: 700;
  `,
  "listing-condition": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.secondaryAccent};
  `,
  "drawer-title": `
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 600;
  `,
}
