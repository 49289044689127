import React, { useState } from 'react';
import Input from '../../../components/Input';
import { useIsMobile } from '../../../utils/useWindowDimensions';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon';
import { colors } from '../../../theme';

const Container = styled.div.attrs({
  className: 'search-input'
})<{ isMobile: boolean; }>(({ isMobile }) => `
  display: flex;
  padding: ${isMobile ? '18px' : '14px 80px'};
  background-color: ${colors.backgroundSecondary};
`);

type Props = {
  onSubmit?: (query: string) => void;
}

export default function SearchInput({
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [value, setValue] = useState('');
  function handleSubmit() {
    onSubmit?.(value);
    setValue('');
  }
  return (
    <Container isMobile={isMobile}>
      <Icon name="search" />
      <Input
        placeholder={t('nav.searchPlaceholder')}
        value={value}
        onChange={e => setValue(e.target.value)}
        containerStyle={{ marginLeft: '18px', flex: 1 }}
        style={{ position: 'relative', top: '1px' }}
        onSubmit={handleSubmit}
      />
    </Container>
  );
}
