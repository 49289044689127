import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { properties } from '../../../theme';
import { CheckState, FilterKey, ItemFiltersState } from './types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import styled from 'styled-components';
import Touchable from '../../../components/Touchable';
import { Close } from '@mui/icons-material';
import Divider from '../detail/Divider';
import FilterExpander from './FilterExpander';
import Drawer from '../../../components/Drawer';

const layoutSettings = properties.layoutSettings.itemFilters;

const useStyles = createUseStyles({
  itemFiltersMobile: {
    marginTop: 24,
  },
  title: {
    marginLeft: 5,
  },
  titleLine: {
    height: 1,
    margin: '19px 0 67px 0',
    width: '30%',
    backgroundColor: '#000',
  },
});

const TitleContainer = styled.div`
  margin: 19px 0 67px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .side-menu-title {
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 1px;
      width: 200px;
      background-color: #000;
      position: absolute;
      left: 0;
      bottom: -14px;
      right: 0;
    }
  }
`;

const FiltersDrawer = styled(Drawer).attrs(({ className, ...attrs }) => ({
  ...attrs,
  className: ['filters-drawer', className].join(' ')
}))`
  .MuiPaper-root {
    padding: 30px;
    overflow: auto;
    height: calc(100% - 60px);
    width: calc(80% - 60px);
    .filter-show-button {
      justify-content: flex-end;
      display: flex;
      margin-top: 32px;
    }
  }
`;

type Props = {
  state: ItemFiltersState;
  handleToggle: (key: FilterKey) => void;
  handleCheckToggle: (key: FilterKey, checkState: CheckState) => void;
}

export default function ItemFiltersMobileView({
  state,
  handleToggle,
  handleCheckToggle,
}: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const keys = Object.keys(state) as [keyof ItemFiltersState];
  const nFilters = keys.filter(key => state[key].items.length).length;
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  return (
    <>
      <div className={[styles.itemFiltersMobile, 'item-filters-mobile-view'].join(' ')}>
        <Button
          state='active'
          size='stretch'
          type='secondary'
          onClick={() => {
            setFiltersDrawerOpen(true);
          }}
          text={t('item.filterN', { n: nFilters })}
        />
      </div>
      <FiltersDrawer anchor='left' open={filtersDrawerOpen} onClose={() => setFiltersDrawerOpen(false)}>
        <TitleContainer className='side-menu-title-container'>
          <Typography className='side-menu-title' variant='drawer-title'>
            {t('item.filter')}
          </Typography>
          {layoutSettings.sideMenu.displayCloseButton && (
            <Touchable className='filters-close-button' onClick={() => {
              setFiltersDrawerOpen(false);
            }}>
              <Close sx={{ position: 'relative', top: 1, fontSize: 24 }} />
            </Touchable>
          )}
        </TitleContainer>
        <Divider withoutMargin />
        {keys.map((key, index) => (
          <FilterExpander
            // @ts-expect-error: 123
            key={key+index}
            state={state[key]}
            // @ts-expect-error: 123
            onToggle={() => handleToggle(key)}
            onCheckToggle={(({ label, isChecked }) => {
              // @ts-expect-error: 123
              handleCheckToggle(key, { label, isChecked });
            })}
          />
        ))}
        {layoutSettings.sideMenu.displayShowButton && (
          <Button
            className='filter-show-button'
            text={t('item.filterShow')}
            state='active'
            onClick={() => {
              setFiltersDrawerOpen(false);
            }}
            {...(layoutSettings.sideMenu.buttonProps ?? {
              type: 'secondary',
              size: 'small',
            })}
          />
        )}
      </FiltersDrawer>
    </>
  );
}
