import React, { useEffect } from 'react';
import FAQExpander from '../../components/FAQExpander';
import { useIsMobile } from '../../utils/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import CopyContainer, { Subtitle } from '../CopyContainer';

type FAQHeader = {
  header: string;
  contents: { title: string; body: string; }[];
}

export default function FAQView() {
  const isMobile = useIsMobile();
  useEffect(() => document.getElementById('layout')?.scrollTo({ top: 0 }), []);
  const { t } = useTranslation();
  const headers = t('infoPages.faq.headers', { returnObjects: true }) as FAQHeader[];

  return (
    <CopyContainer
      title={t(`infoPages.faq.title`)}
      subtitles={headers.map(h => h.header)}
      contentStyle={{ width: isMobile ? '100%' : '80%' }}
    >
      {headers.map(({ header, contents }, headerIndex) => (<div key={`${header}-${headerIndex}`} style={{ marginBottom: '64px' }}>
        <Subtitle>{header}</Subtitle>
        {contents.map(c => (
          <FAQExpander
            key={headerIndex + '-' + c.title}
            title={c.title}
            text={<div dangerouslySetInnerHTML={{__html: c.body}} />}
          />
        ))}
      </div>))}
    </CopyContainer>
  );
}
