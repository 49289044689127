import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import ItemRow from "./ItemRow";
import { searchItems } from "../../api/items/queries";
import { ItemSearchParams, ItemsPaginationReturn } from "../../api/items/types";
import { useIsMobile } from "../../utils/useWindowDimensions";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import zIndexes from "../../utils/zIndexes";
import styled from "styled-components";

type StyleProps = {
  isMobile: boolean;
};
const useStyles = createUseStyles<string, StyleProps>({
  itemsTable: {
    display: "flex",
    flexWrap: "wrap",
    margin: "30px auto",
    fontSize: 0,
    justifyContent: "center",
    position: "relative",
    zIndex: zIndexes.itemsTable,
  },
});

type Props = {
  searchParams: ItemSearchParams;
  onQuery?: (query: ItemsPaginationReturn) => void;
};

export default function ItemsTable({ searchParams, onQuery }: Props) {
  const query = searchItems({ limit: 12, offset: 0 }, searchParams);
  const { data, facets, hasNextPage, isLoading, fetchNextPage } = query;
  useEffect(() => {
    onQuery?.(query);
  }, [facets]);
  const isMobile = useIsMobile();
  const styles = useStyles({ isMobile });
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.itemsTable}>
        {data.map((item, index) => (
          <ItemRow index={index} key={item.id + index.toString()} item={item} />
        ))}
      </div>
      {hasNextPage && (
        <LoadMoreButton
          size="medium"
          state={isLoading ? "disabled" : "active"}
          type="primary"
          onClick={() => fetchNextPage()}
          text={t("item.loadMore")}
        />
      )}
    </div>
  );
}

const LoadMoreButton = styled(Button)`
  display: flex;
  justify-content: center;
`;
