import {
  ChangePasswordBody,
  RegistrationBody,
  RegistrationResponse,
  UserMe,
  UserMeUpdate,
  ResetPasswordConfirmBody,
  GetTokenPairBody,
  TokenPair,
  VerifyEmailBody,
  RegistrationError,
} from './types';
import fetcher, { fetchErrHandler } from '../fetcher';
import { APIError } from '../common/types';

export function changePassword(body: ChangePasswordBody): Promise<void> {
  return fetcher({
    path: '/users/change-password/',
    options: {
      body: JSON.stringify(body),
      method: 'PUT'
    },
    authenticate: false,
  });
}

export function getMe(accessToken: string): Promise<UserMe> {
  return fetcher({
    path: '/users/me/',
    options: { method: 'GET' },
    authenticate: true,
    accessToken,
  });
}

export function updateMe(meToUpdate: UserMeUpdate): Promise<UserMe> {
  return fetcher({
    path: '/users/me/',
    options: {
      body: JSON.stringify(meToUpdate),
      method: 'PUT'
    },
    authenticate: true,
  });
}

export function deleteMe(password: string): Promise<void> {
  return fetcher({
    path: '/users/me/',
    options: {
      body: JSON.stringify({ password }),
      method: 'DELETE'
    },
    authenticate: true,
  });
}

export function register(body: RegistrationBody) {
  return fetchErrHandler<RegistrationResponse, RegistrationError>(() => fetcher({
    path: '/users/register/',
    options: {
      body: JSON.stringify(body),
      method: 'POST',
    },
    authenticate: false,
  }));
}

export function resetPassword(email: string) {
  return fetchErrHandler<{}, APIError<{ email: string }>>(() => fetcher({
    path: '/users/reset-password/',
    options: {
      body: JSON.stringify({ email }),
      method: 'POST',
    },
    authenticate: false,
  }));
}

export function resetPasswordConfirm(user_id: string, body: ResetPasswordConfirmBody) {
  return fetchErrHandler<{}, APIError<ResetPasswordConfirmBody>>(() => fetcher({
    path: `/users/reset-password-confirm/${user_id}/`, 
    options: {
      body: JSON.stringify(body),
      method: 'PUT',
    },
    authenticate: false,
  }));
}

/**
 * @throws {LoginError}
 */
export function getTokenPair(body: GetTokenPairBody): Promise<TokenPair> {
  return fetcher({
    path: '/users/token/',
    options: {
      body: JSON.stringify(body),
      method: 'POST',
    },
    authenticate: false,
  });
}

export function refreshToken(body: Pick<TokenPair, 'refresh'>): Promise<Pick<TokenPair, 'access'>> {
  return fetcher({
    path: '/users/token/refresh/',
    options: {
      body: JSON.stringify(body),
      method: 'POST',
    },
    authenticate: false,
  });
}

export function verifyEmail(body: VerifyEmailBody) {
  return fetcher({
    path: '/users/verify-email/',
    options: {
      method: 'PUT',
      body: JSON.stringify(body),
    },
    authenticate: false,
  });
}

export function resendVerificationEmail() {
  return fetcher({
    path: '/users/resend-verification-email/',
    options: {
      method: 'POST',
    },
    authenticate: false,
  });
}
