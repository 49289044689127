import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Expander from '../../../components/Expander';
import Divider from '../detail/Divider';
import { ItemFilterCheckBox, ItemFilterState } from './types';
import { getItemFilterCheckboxes } from './utils';

const StyledDiv = styled.div.attrs({
  className: 'filter-expander'
})`
`;

type Props = {
  state: ItemFilterState;
  onToggle: () => void;
  onCheckToggle: (checkbox: ItemFilterCheckBox) => void;
}

export default function FilterExpander({
  state,
  onToggle,
  onCheckToggle,
}: Props) {
  const { isExpanded, items, label } = state;
  const { t } = useTranslation();
  if (!items.length) return null;
  return (
    <StyledDiv>
      <Expander
        title={t(label)}
        isExpanded={isExpanded}
        onToggle={onToggle}
        contents={(
          <div>
            {getItemFilterCheckboxes(state, onCheckToggle)}
          </div>
        )}
      />
      <Divider withoutMargin />
    </StyledDiv>
  );
}
