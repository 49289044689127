export default {
  drawerAboveNav: 20,
  carouselBigPic: 15,
  navRightProfileDropdownContent: 12,
  navContainer: 11,
  usps: 11,
  navDesktopFlexContainer: 11,
  drawerBelowNav: 10,
  backdrop: 10,
  navMobileContainer: 10,
  carouselZoom: 2,
  dropdownContent: 2,
  carouselMinifiedSelector: 1,
  layoutGoBack: 1,
  navMobileSubLogo: 1,
  itemRowImgPlaceholder: 1,
  itemRowImg: 1,
  itemRowSoptionsContainer: 1,
  homeContainer: 0,
  itemsTable: 0,
}
