import { APIError } from './types';

export function handleAPIError<T>(error: APIError<T>): string {
  const { detail, ...rest } = error;
  let str = detail ?? '';
  Object.keys(rest).map(key => {
    const k = key as keyof T;
    str += rest[k]?.join('<br />');
  });
  return str;
}
