import { properties } from "../../theme";
import { Item, MyItem } from "./types";

type ImgItem = Pick<MyItem|Item, 'partnerimage_set'|'itemimage_set'>;

export function getThumb(item?: ImgItem): string|undefined {
  return getItemImg(item, properties.partnerImageArgs.thumb);
}

export function getItemImg(item: ImgItem|undefined, imageArgs: string): string|undefined {
  if (!item) return undefined;
  const { partnerimage_set, itemimage_set } = item;
  const partnerUrl = partnerimage_set?.[0]?.url;
  const imgUrl = partnerUrl ?? itemimage_set?.[0]?.item_image;
  return getItemImgFromUrl(imgUrl, imageArgs, !!partnerUrl);
}

export function getItemImgFromUrl(url: string|undefined, imageArgs: string, isPartnerImg: boolean): string|undefined {
  if (!url) return undefined;
  let imgUrl: string;
  if (isPartnerImg) {
    imgUrl = url + imageArgs;
  } else {
    imgUrl = url;
  }
  return imgUrl;
}
