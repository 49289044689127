import React from 'react';
import Checkbox from "../../../components/Checkbox";
import { ItemFilterCheckBox, ItemFilterState } from "./types";

export function getItemFilterCheckboxes (
  state: ItemFilterState,
  onCheckToggle: (checkbox: ItemFilterCheckBox) => void,
) {
  return state.items.map(checkbox => {
    const { label, labelComponent, isChecked } = checkbox;
    return (
      <Checkbox
        key={label}
        label={label}
        labelComponent={labelComponent}
        isChecked={isChecked}
        onToggle={() => onCheckToggle({ ...checkbox, isChecked: !isChecked })}
      />
    )
  });
}
