import { useEffect } from 'react';
import * as localStorage from './localStorage';

export default function useLocalStorageEventListener<K extends keyof localStorage.LocalStorage>(
  localStorageKey: K,
  callback: () => void,
  callOnInit = false,
) {
  const eventType = localStorage.getEventType(localStorageKey);
  useEffect(() => {
    if (callOnInit) callback();
    window.addEventListener(eventType, callback);
    return () => window.removeEventListener(eventType, callback);
  }, []);
}
