import { useParams } from "react-router-dom";
import { useQuery } from "../../../api/common/hooks";
import { getItem } from "../../../api/items/endpoints";
import { properties } from "../../../theme";
import { defaultSustainabilityOptions } from "./utils";

export default function useItem() {
  const { id } = useParams();
  const { data: item } = useQuery(['item', id], () => getItem(id));
  const sustainabilityOptions = [...(item?.sustainability_options ?? []), ...defaultSustainabilityOptions]
    .filter(key => key in properties.sustainabilityOptions)
    .map(key => properties.sustainabilityOptions[key]);
  return {
    item,
    sustainabilityOptions,
  }
}
