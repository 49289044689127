import fetcher, { fetchErrHandler } from '../fetcher';
import { Item } from '../items/types';
import { Purchase, CompletePurchaseError, AccountPurchaseRequest, AccountPurchaseResponse, AccountlessPurchaseRequest, AccountlessPurchaseResponse } from './types';

export function accountPurchase(item_id: Item['id'], body: AccountPurchaseRequest) {
  return fetchErrHandler<AccountPurchaseResponse, CompletePurchaseError>(() => fetcher({
    path: `/purchases/listings/${item_id}/purchase/`,
    authenticate: true,
    options: {
      method: 'POST',
      body: JSON.stringify(body),
    },
  }));
}

export function accountlessPurchase(item_id: Item['id'], body: AccountlessPurchaseRequest) {
  return fetchErrHandler<AccountlessPurchaseResponse, CompletePurchaseError>(() => fetcher({
    path: `/purchases/listings/${item_id}/accountless-purchase/`,
    authenticate: false,
    options: {
      method: 'POST',
      body: JSON.stringify(body),
    },
  }));
}

export const getAccountlessPurchase = (id: Purchase['id']) => (
  fetcher<Purchase>({
    path: `/purchases/my-accountless-purchase/${id}/`,
    authenticate: false,
    options: {
      method: 'GET',
    },
  })
)

export const getPurchase = (id: Purchase['id']) => (
  fetcher<Purchase>({
    path: `/purchases/my-purchase/${id}/`,
    authenticate: true,
    options: {
      method: 'GET',
    },
  })
)
