import {
  Pagination,
  PaginationParams,
} from '../common/types';
import { Partner } from './types';
import fetcher from '../fetcher';

export function getPartners(params: PaginationParams): Promise<Pagination<Partner>> {
  return fetcher({
    path: '/partners/partner/',
    params,
    options: { method: 'GET' },
    authenticate: true
  });
}

export function getPartner(id: string): Promise<Partner> {
  return fetcher({
    path: `/partners/partner/${id}/`,
    options: { method: 'GET', },
    authenticate: true
  });
}
