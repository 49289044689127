/* Polyfill for old browsers */
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/number/is-nan';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import { RootContextProvider } from './contexts/RootContext';
import './index.css';
import './fonts.css';
import 'swiper/css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import moment from 'moment';
import './utils/ensure-basename';
import { properties } from './theme';

moment.locale('sv');
if (process.env.NODE_ENV == 'development') console.log(process.env);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <RootContextProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={properties.routerBaseName ?? '/'}>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </RootContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
