import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as auth from '../../utils/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import recrqlColors from '../../theme/recrql/colors';
import Typography from '../../components/Typography';
import { t } from 'i18next';
import Button from '../../components/Button';
import { GetTokenPairBody, LoginError } from '../../api/users/types';
import AuthContainer, { useAuthContainerStyles } from './AuthContainer';
import LayoutContext from '../../contexts/LayoutContext';
import { resetPassword } from '../../api/users/endpoints';
import Form, { FormFieldState, formIsValid as formIsValidF, formObject, updateFormFields } from '../../components/Form';

const useStyles = createUseStyles({
  mail: {
    marginBottom: '31px !important',
    width: '100%',
  },
  password: {
    marginBottom: '19px !important',
    width: '100%',
  },
  forgotPassword: {
    alignSelf: 'flex-end',
    marginBottom: 21,
  },
  divider: {
    height: 1,
    width: '100%',
    margin: '24px 0',
    backgroundColor: recrqlColors.neutralGrey.background,
  },
  top: {
    alignItems: 'inherit',
    display: 'inherit',
    flexDirection: 'inherit',
    width: 'inherit',
  },
  bottom: {
    alignItems: 'inherit',
    display: 'inherit',
    flexDirection: 'inherit',
    width: 'inherit',
  },
});

export default function LoginView() {
  const [form, setForm] = useState<FormFieldState<GetTokenPairBody>>({
    email: {
      type: 'email',
      label: t('user.mail'),
      value: '',
      isValid: (value) => !!value,
      isRequired: true,
    },
    password: {
      isRequired: true,
      type: 'password',
      label: t('user.password'),
      value: '',
      isValid: (value) => value.length >= 8,
    },
  });
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [error, setError] = useState<LoginError>();
  const styles = useStyles();
  const authContainerStyles = useAuthContainerStyles();
  const [params] = useSearchParams();
  const redirectUri = params.get('redirectUri');
  const navigate = useNavigate();
  const [__, setLayoutContext] = useContext(LayoutContext);
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);
  const isLoading = isLoadingLogin || isLoadingResetPassword;
  useEffect(() => {
    setLayoutContext(p => ({
      ...p,
      backButtonDisabled: isLoading,
    }));
    setForm(updateFormFields(form, 'isDisabled', isLoading));
  }, [isLoading]);
  useEffect(() => {
    setLayoutContext(p => ({
      ...p,
      handleGoBack: () => navigate('/'),
    }));
    return () => {
      setLayoutContext(p => ({
        ...p,
        backButtonDisabled: false,
        handleGoBack: undefined,
      }));
    };
  }, []);

  async function handleLogin () {
    const body = formObject(form, null);
    if (!form || !body) return;
    setError(undefined);
    setIsLoadingLogin(true);
    try {
      await auth.login(body);
    } catch (e) {
      const err = e as LoginError;
      if (err) {
        setError(err);
      }
      auth.unauthenticate();
    } finally {
      setIsLoadingLogin(false);
    }
  }
  async function handleResetPassword() {
    if (error?.email) return;
    if (!form.email.isValid(form.email.value, form) || error?.email) {
      setError(p => ({ ...p, email: [t('forgotPassword.emailFieldRequired')] }));
      return;
    }
    const email = form.email.value;
    setIsLoadingResetPassword(true);
    const [_, err] = await resetPassword(email);
    setIsLoadingResetPassword(false);
    if (err) {
      setError(p => ({ ...p, email: [err.email?.at(0) ?? err.detail ?? t('forgotPassword.unknownError')] }));
    } else {
      navigate(`/forgot-password?email=${encodeURIComponent(email)}`);
    }
  }
  const formIsValid = useMemo(() => formIsValidF(form), [form]);
  const loginButtonState = formIsValid && !isLoading ? 'active' : 'disabled';
  return (
    <AuthContainer title={t('user.logIn')}>
      <div className={styles.top}>
        <Form<GetTokenPairBody>
          validationError={error}
          state={form}
          onChange={setForm}
          onChangeError={setError}
          errorStyle={{
            alignSelf: 'flex-start',
          }}
        />
        <Button
          context='recrql'
          state={isLoading ? 'disabled' : 'active'}
          type='link'
          size='auto'
          text={t('user.forgotPassword')}
          isLoading={isLoadingResetPassword}
          className={styles.forgotPassword}
          onClick={handleResetPassword}
        />
        <Button
          state={loginButtonState}
          type='primary'
          context='recrql'
          text={isLoadingLogin ? t('user.loggingIn') : t('user.logIn')}
          isLoading={isLoadingLogin}
          onClick={handleLogin}
          size='stretch'
          buttonStyle={{ borderRadius: 100, padding: 10 }}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.bottom}>
        <Typography className={authContainerStyles.title} variant='general-h2'>{t('user.newCustomer')}</Typography>
        <Button
          state={isLoading ? 'disabled': 'active'}
          type='secondary'
          context='recrql'
          text={t('user.register')}
          onClick={() => navigate('/register' + (redirectUri ? `?redirectUri=${redirectUri}` : ''))}
          size='stretch'
        />
      </div>
    </AuthContainer>
  );
}
