import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { t } from 'i18next';
import AuthContainer from '../user/AuthContainer';
import recrqlColors from '../../theme/recrql/colors';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

export default function VerifyEmailConfirmation() {
  const navigate = useNavigate();
  return (
    <AuthContainer title={(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckCircle sx={{ marginRight: '7px', fontSize: '23px' }} htmlColor={recrqlColors.logoOrAccent.background} />
        {t('verifyEmail.confirmationTitle')}
      </div>
    )}>
      <Button
        text={t('verifyEmail.continue')}
        type='primary'
        context='recrql'
        state='active'
        size='stretch'
        onClick={() => navigate('/')}
      />
    </AuthContainer>
  );
}
