import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from './Button';

const StyledButton = styled(Button)`
  display: inline-block;
  .button {
    padding: 0px;
    align-items: unset;
    justify-content: unset;
  }
`;

export type Props = {
  href: string;
} & Pick<ButtonProps, 'text'|'variant'>;

export default function Hyperlink ({
  href,
  ...props
}: Props) {
  return (
    <StyledButton
      size='auto'
      context='partner'
      state='active'
      onClick={() => window.open(href)}
      type='link'
      {...props}
    />
  );
}
