import { useQuery } from 'react-query';
import {
  getPartners as getPartnersEP,
  getPartner as getPartnerEP,
} from './endpoints';
import { PaginationParams } from '../common/types';
import { usePagination } from '../common/hooks';
import { Partner } from './types';

export function getPartners(params: PaginationParams) {
  return usePagination<Partner>('partners', params, getPartnersEP, false);
}

export function getPartner(id: string) {
  return useQuery(['partner', id], () => getPartnerEP(id));
}
