import React, { useEffect, useState } from 'react';

export type SvgImgProps = {
  src: string;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
export default function SvgImg ({ src, ...props }: SvgImgProps) {
  const [icon, setIcon] = useState('');

  useEffect(() => {
    import(`../${src}`).then(importedIcon => {
      setIcon(importedIcon.default);
    });
  }, []);

  return <img alt='' src={icon} height='auto' {...props} />;
}
