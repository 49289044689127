import React, { Dispatch, SetStateAction, useState } from 'react';

export interface LayoutContextState {
  contentStyle?: React.CSSProperties;
  backButtonDisabled: boolean;
  handleGoBack?: () => void;
}

const defaultState: LayoutContextState = {
  backButtonDisabled: false,
};

const LayoutContext = React.createContext<[LayoutContextState, Dispatch<SetStateAction<LayoutContextState>>]>([defaultState, ()=>({})]);

export const LayoutContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(defaultState);
  return (
    <LayoutContext.Provider value={[state, setState]}>
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContext;
