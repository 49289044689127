import React from 'react';
import Typography from '../../components/Typography';
import CopyContainer, { Subtitle } from '../CopyContainer';

export default function PrivacyPolicy() {
  return (
    <CopyContainer
      title='RECRQL - PRIVACY POLICY'
      subtitles={[
        '1. WHEREAS',
        '2. CHANGES TO THE PRIVACY POLICY',
        '3. OBJECTIVES AND LEGAL BASIS FOR HSS’ PROCESSING OF PERSONAL DATA',
        '4. SHARING OF PERSONAL DATA TO THIRD PARTIES',
        '5. STORING OF PERSONAL DATA',
        '6. TRANSFER OF PERSONAL DATA OUTSIDE THE EU/EES',
        '7. THE RIGHT TO REQUEST INFORMATION',
        '8. THE RIGHT TO RECTIFICATION',
        '9. THE RIGHT TO ERASURE',
        '10. THE RIGHT TO RESTRICTION OF PROCESSING',
        '11. DELETION OF PERSONAL DATA',
        '12. THE RIGHT TO WITHDRAW CONSENT AND OBJECT TO PROCESSING',
        '13. THE RIGHT TO DATA PORTABILITY',
        '14. THE RIGHT TO COMPLAIN',
        '15. SECURITY',
        '16. CONTACT INFORMATION'
      ]}
    >
      <Typography variant='general-body1' style={{ display: 'block' }}>
          <Subtitle>1. WHEREAS</Subtitle>
          1.1 This   privacy   policy   always   applies,   unless
          otherwise agreed in writing, for the processing
          of   personal   data   performed   by   Hjelmberg
          Sustainable Solutions AB, reg.no 559295-5073,
          (”<b>HSS</b>”).<br /><br/>
          1.2 Concepts   in   this   privacy   policy,   e.g.   “data
          controller”,   “data   subject”,   “personal   data”,
          “processing”,   “data   processor”,   “standard
          contractual   clauses”   and   “supervisory
          authority”, shall have the meaning ascribed to
          them   in   Regulation   (EU)   2016/679   of   the
          European  Parliament and of  the Council  (the
          “GDPR”) or otherwise in the, where applicable,
          unless   the   circumstances   distinctly   require
          another order of interpretation. <br /><br/>
          1.3 This privacy policy ensures that HSS
          processes   personal   data   in   accordance   with
          applicable privacy laws and regulations. <br /><br/>
          1.4 Below   follows   a   description   of   how   HSS
          processes personal data as well as information
          regarding the rights of the data subjects and
          how the data subjects can get in contact with
          HSS and/or exercise its rights.
          <Subtitle>2. CHANGES TO THE PRIVACY POLICY</Subtitle>
          HSS may at its sole discretion make changes to
          this privacy policy. HSS undertakes to inform
          affected data subjects of any material changes to
          the privacy policy.
          <Subtitle>3. OBJECTIVES AND LEGAL BASIS FOR HSS’ PROCESSING OF PERSONAL DATA</Subtitle>
          3.1 The   main   purpose   of   HSS’   processing   of
          personal   data   is   to   enable   HSS   to   offer   its
          services in full, provide information about the
          services   and   market   the   services.   HSS   may
          however also process personal data due to a
          legal obligation (e.g. when HSS must save the
          data according to accounting rules), or in order
          to defend HSS against legal claims.<br /><br/>
          3.2 The collection and processing of personal data in
          order to provide, market, or inform about HSS’
          services is made on the legal basis of either the
          data subjects approval, a contractual obligation
          between   the   data   subject   and   HSS   (or   HSS’
          principal   if   applicable),   or   an   existing   legal
          obligation   for   HSS   (or   HSS’   principal   if
          applicable).   Exceptions   are   made   for   cases
          where   a   prior   consent   is   not   possible   for
          practical reasons, the processing of the data is
          permitted   by   law,   and   when   HSS   has   a
          legitimate interest  in processing the personal
          data   (e.g.   for   marketing,   follow-up   of   the
          services,   or   for   exercising   or   defending   HSS
          against legal claims) according to a so called
          balance of interests.<br /><br/>
          3.3 The collection and processing of personal data in
          order   to   comply   with   legal   obligations   or   in
          order   to   defend   HSS   against   legal   claims   is
          made on the legal basis of the legal obligation or
          a balance of interests. <br /><br/>
          3.4 HSS  does   not  process   any  sensitive   personal
          data   regarding   the   data   subject   without   the
          express consent of the data subject (either to
          HSS or HSS’ principal if applicable).
          <Subtitle>4. SHARING OF PERSONAL DATA TO THIRD PARTIES</Subtitle>
          4.1 HSS will not share personal data with any third
          party in any other way than what follows from
          this privacy policy, unless HSS is given prior
          consent from the data subject, or unless HSS is
          obliged   to   do   so   following   the   applicable
          regulations or unless the personal data is shared
          in   connection   with   an   ongoing   legal-,
          administrative- or recovery procedure in which
          the data subject and HSS are both parties.<br /><br/>
          4.2 HSS may use subcontractors for the processing
          of   personal   data   (so   called   personal   data
          assistants).   HSS   may   also   need   to   use   the
          subcontractor’s services to store personal data.
          HSS   will   always   limit   such   subcontractors&apos;
          access to personal data to the minimal amount
          needed for HSS to still be able to reach the
          objective of the processing of the personal data. <br /><br/>
          4.3 HSS requires all subcontractors to (i) protect
          personal data in accordance with this privacy
          policy and (ii) not use or disclose personal data
          for any purpose other than to provide the agreed
          upon services to HSS. 
          <Subtitle>5. STORING OF PERSONAL DATA</Subtitle>
          5.1 All personal data stored by HSS is stored locally
          with HSS, or on an external server provided by
          HSS’ personal data assistant in the EU/EEA.<br /><br/>
          5.2 Personal data will not be stored for any longer
          than necessary, with regards to the objective of
          the processing, and taking into account HSS’
          legal   obligations   regarding   to   accounting
          regulations etc. <br /><br/>
          5.3 HSS regularly deletes personal data which is no
          longer needed with regards to the objective of
          the   processing,   in   accordance   with   the
          applicable regulations in force at any time.
          <Subtitle>6. TRANSFER OF PERSONAL DATA OUTSIDE THE EU/EES</Subtitle>
          HSS will not transfer personal data to countries
          outside the EU/EEA. Should HSS need to do so
          in the future, e.g. due to HSS choosing a digital
          service provider based in a country outside the
          EU/EEA, HSS will only transfer personal data if
          the receiving country has an adequate level of
          protection   according   to   applicable   laws   and
          regulations.
          <Subtitle>7. THE RIGHT TO REQUEST INFORMATION</Subtitle>
          7.1 The  data   subject  has  a  right  to  request   and
          obtain,   free   of   charge,   information   regarding
          what   personal   data,   relating   to   such   data
          subject, is being processed, at any time, by HSS
          (a so-called register extract). <br /><br/>
          7.2 If   a   data   subject   wishes   to   know   if   HSS
          processes   any   personal   data   about   the   data
          subject, he or she can send a written request to
          HSS in accordance with section 16 below. In the
          request,   the   data   subject   needs   to   indicate
          specifically what kind of information the data
          subject   is   interested   in   receiving   (unless   the
          data   subject   is   interested   in   receiving
          information   about   all   personal   data   being
          processed). That way, HSS can provide the data
          subject with information that is relevant. <br /><br/>
          7.3 If a data subject repeatedly sends requests for
          extracts from the register, HSS may charge a
          fee   or,   in   some   cases,   in   accordance   with
          statutory   law,   refuse   to   comply   with   the
          requests.<br /><br/>
          7.4 The extract from the register will be sent to the
          data subject within 30 days from the time HSS
          received the request. If the extract is extensive
          and HSS needs more time or if HSS for some
          reason cannot comply with the data subject’s
          request, HSS will without undue delay notify the
          data subject thereof.
          <Subtitle>8. THE RIGHT TO RECTIFICATION</Subtitle>
          8.1 In order to fulfill its obligations to always have
          accurate   and   relevant   personal   data,   HSS
          systematically   works   with   its   registers   and
          updates personal data where necessary.<br /><br/>
          8.2 If a data subject notices that the personal data
          HSS   processes   is   incorrect   or   if   HSS   lacks
          important personal data, the data subject has
          the right to have their personal data corrected. <br /><br/>
          8.3 HSS normally performs simple data corrections
          without consideration, but in some cases HSS
          may need to consider the data subject&apos;s request.
          HSS will not approve the data subject&apos;s request
          if it is impossible or requires an unreasonable
          amount of work. <br /><br/>
          8.4 In the event that personal data is changed at the
          data   subject&apos;s   request,   HSS   will   inform   any
          subcontractors and partners that processes the
          personal data about the change. Upon written
          request by the data subject, HSS will also inform
          the data subject about to whom the corrections
          have been submitted.
          <Subtitle>9. THE RIGHT TO ERASURE</Subtitle>
          9.1 A data subject has the right to request that HSS
          erases personal data when:
          <ol type='a'>
            <li>
              they are no longer needed for the objectives
              for which they have been collected and for
              which they are being processed;
            </li>
            <li>
              HSS   processes   personal   data   on   the   legal
              basis   of   consent   and   the   data   subject
              withdraws said consent;
            </li>
            <li>
              HSS   processes   personal   data   for   direct
              marketing and the data subject opposes the
              continued processing for this objective;
            </li>
            <li>
              HSS   processes   personal   data   on   the   legal
              basis of a balance of interests and there are
              no   legitimate   interests   that   outweighs   the
              data subject&apos;s interest;
            </li>
            <li>
              HSS   does   not   process   personal   data   in
              accordance with applicable regulations;
            </li>
            <li>
              It is required that personal data is erased in
              order to fulfill a legal obligation; or
            </li>
            <li>
              there is another relevant legal basis for the
              data subjects request to erase the personal
              data.
            </li>
          </ol>
          9.2 HSS   has   the   right   to   refrain   from   erasing
          personal data if HSS needs to retain these in
          order to fulfill a legal obligation, to be able to
          make legal claims against a data subject, or in
          defense against legal claims from a data subject.<br /><br/>
          9.3 When   HSS   receives   a   data   subject’s   request
          regarding   erasure,   HSS   will   conduct   an
          assessment   in   order   to   evaluate   if   there   are
          reasons to erase the personal data. The data
          subject   will   then   be   informed   about   HSS’
          assessment. If the personal data are erased at
          the data subject’s request, HSS will also instruct
          suppliers   and   third-party   partners,   to   which
          personal   data   has   been   transferred,   that   the
          personal data are to be erased.
          <Subtitle>10. THE RIGHT TO RESTRICTION OF PROCESSING</Subtitle>
          10.1 A data subject has the right to request the
          restriction of HSS’ processing of personal data
          when:
          <ol type='a'>
            <li>the data subject has disputed the accuracy of
            the personal data, during the time HSS has
            the   opportunity   to   check   whether   the
            personal data are correct;</li> 
            <li>the processing is illegal, and the data subject
            opposes that the personal data is deleted and
            instead requests a limitation of its processing;</li>
            <li>HSS no longer needs the personal data for
            the purposes of the processing, but the data
            subject needs the personal data to be able to
            determine, enforce or defend legal claims; or
            d) the data subject has objected to processing in
            accordance   with   section   12   below,   when
            awaiting   information   of   the   legitimacy   of
            whether   HSS’   interests   outweigh   the
            interests of the data subject.</li>
          </ol>
          10.2 Restriction of processing implies that the
          personal data will be marked, so that they in the
          future may only be processed for certain limited
          purposes.
          <Subtitle>11. DELETION OF PERSONAL DATA</Subtitle>
          11.1 Personal data will not be retained for any
          longer   than   is   necessary   with   regard   to   the
          objective   of   the   processing,   and   HSS   will
          otherwise delete personal data in the manner
          that follows from applicable regulations.<br /><br/>
          11.2 If a data subject requests that personal data
          shall be deleted, HSS will delete or de-identify
          the personal data no later than 30 days from the
          reception of the request. This is provided that
          the personal data are not required to be saved in
          order for HSS to fulfill its legal obligations or to
          be able to exercise its legal claims.
          <Subtitle>12. THE RIGHT TO WITHDRAW CONSENT AND
          OBJECT TO PROCESSING</Subtitle>
          12.1 A data subject has the right to object to
          HSS’ processing of personal data which HSS
          processes   on   the   legal   basis   of   a   so-called
          balance of interests. <br /><br/>
          12.2 If a data subject wishes to exercise the right
          to  object  to the  processing,  the data  subject
          needs to specify in writing which processing the
          data subject objects to. <br /><br/>
          12.3 In   the   event   of   an   objection   by   a   data
          subject, HSS may only continue to process the
          personal data if HSS can show that there are
          compelling  and entitling  reasons  for why  the
          personal data must be processed, and provided
          such   reasons   outweigh   the   data   subject&apos;s
          interests.<br /><br/>
          12.4 If   personal   data   is   processed   for   direct
          marketing, a data subject always has the right to
          object to the processing at any time.
          <Subtitle>13. THE RIGHT TO DATA PORTABILITY</Subtitle>
          13.1 If a data subject has provided his or her
          personal data to HSS, the data subject may, in
          some cases, be entitled to extract his or her
          personal data in order to, for example, move
          them to another company. <br /><br/>
          13.2 In order for a data subject to be able to use
          his or her right to so called data portability, the
          data subject&apos;s request must relate to personal
          data that the data subject himself/herself has
          provided to HSS and which HSS processes with
          the consent of the data subject or to fulfill an
          agreement with the data subject. <br /><br/>
          13.3 The right to data portability does not apply
          when   HSS’   processing   of   the   data   subject&apos;s
          personal data is based on a balance of interests
          or a legal obligation for HSS. The right to data
          portability does not apply when data portability
          is technically difficult to implement.
          <Subtitle>14. THE RIGHT TO COMPLAIN</Subtitle>
          14.1 A data subject has the right to complain on
          HSS’ processing of personal data. <br /><br/>
          14.2 HSS kindly asks that any data subject who
          wishes  to  complain   initially  contacts  HSS,  so
          that HSS can address the complaint and assist
          the data subject in the best way possible. <br /><br/>
          14.3 Notwithstanding the above, a data subject
          always   has   the   right   to   submit   his   or   her
          complaints   directly   to   the   relevant   data
          protection authority.
          <Subtitle>15. SECURITY</Subtitle>
          15.1 HSS   undertakes   to   implement   all
          appropriate   technical   and   organizational
          security   measures,   that   are   required   in
          accordance   with   applicable   regulations,   to
          ensure a high level of security, appropriate to
          the risks of the processing of personal data, and
          to   protect   personal   data   from   accidental   or
          unlawful   destruction,   loss,   alteration,   and
          unauthorized   disclosure   of,   or   access   to,
          processed personal data.<br /><br/>
          15.2 Upon   written   request   by   a   data   subject,
          HSS will inform the data subject about which
          security  measures  are  being  implemented  for
          the personal data of the data subject. 
          <Subtitle>16. CONTACT INFORMATION</Subtitle>
          16.1 If a data subject wishes to exercise his or
          her rights under this privacy policy, a request
          shall be made in written form and sent to HSS
          by email to <a href="mailto:hello@recrql.com" className="link">hello@recrql.com</a>.<br /><br/>
          16.2 If the data subject has any questions about
          this policy or HSS’ personal data processing, the
          data   subject   can   contact   HSS   by   email   to&nbsp;
          <a href="mailto:hello@recrql.com" className="link">hello@recrql.com</a>.
        </Typography>
    </CopyContainer>
  );
}
