import React from 'react';
import styled from 'styled-components';
import { DivProps } from '../utils/types';
import { useIsMobile } from '../utils/useWindowDimensions';

type StyleProps = {
  isMobile: boolean;
}

const StyledDiv = styled.div.attrs(({ className }) => ({
  className: ['content-container', className].join(' ')
}))<StyleProps>(({ isMobile }) => (`
  padding: ${isMobile ? '24px 22px' : '25px 80px'};
`));

export default function ContentContainer(props: DivProps) {
  const isMobile = useIsMobile();
  return (
    <StyledDiv isMobile={isMobile} {...props} />
  );
}
