import { Listing } from '../listings/types';
import { SearchParams } from '../common/elasticSearch';
import { PaginationReturn } from '../common/hooks';
import { Facets, Pagination } from '../common/types';
import { properties } from '../../theme';

export interface MyItem {
  id: string;
  title: string;
  partner: string;
  description: string;
  original_purchase_price: number;
  original_purchase_date: string;
  color: string;
  color_group: string;
  size: string;
  age_group: string;
  gender_category: string;
  category_1: string;
  category_2: string;
  google_product_category: string;
  weight_in_grams: number;
  delivery_price: number;
  itemimage_set: ItemImage[];
  partnerimage_set: PartnerItemImage[];
}

export type MyListing = Omit<Listing, 'seller_id'|'total_price'> & {
  id: string;
  item: string;
}

export interface Item {
  id: string;
  title: string;
  partner_product_id: string;
  description: string;
  original_list_price: number;
  color: string;
  size: string;
  material?: string;
  age_group: string; // Infant/Adult/Kids/Toddler
  gender_category: string;
  category_1: string;
  category_2: string;
  itemimage_set: ItemImage[];
  partnerimage_set: PartnerItemImage[];
  listing: Listing;
  activities: string;
  sustainability_options?: string[];
  weight_in_grams: number;
  // Stjärnurmakarna
  raw_data?: any;
  // Outnorth
  properties?: { [key: string]: string; };
  brand?: string;
  sustainability?: string;
  product_url?: string;
}

export interface ItemImage {
  description: string;
  item_image: string;
}

export interface PartnerItemImage {
  description: string;
  url: string;
}

export type ItemFilterFields = { [key: string]: string[]; }

export function isFilterKey (key: string): boolean {
  const keys = properties.filters.map(k => k.key);
  console.log({
    keys,
    key
  })
  return keys.includes(key);
}

export type ItemSearchParams = SearchParams<ItemFilterFields>;
export type ItemsPaginationReturn = PaginationReturn<Item, Facets, Pagination<Item, Facets>>;

export interface SustainabilityOption {
  title: string;
  description: string;
  icon: string;
  default: boolean;
}
